import React from 'react'
import { graphql } from 'gatsby'
import Home from '../containers/Home'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'

export default class extends React.PureComponent {
	render () {
		return (
			<Layout {...this.props}>
				<SEO
					lang={this.props.pageContext.langKey}
					title={this.props.data.data.edges[0].node.mainPageMetaTitle}
					description={this.props.data.data.edges[0].node.mainPageMetaDescription}
					ogDescription={this.props.data.data.edges[0].node.mainPageMetaDescription}
					twitterDescription={this.props.data.data.edges[0].node.mainPageTwitterDescription}
					cover={this.props.data.data.edges[0].node.mainPageMetaImage.file.url}
					ogCover={this.props.data.data.edges[0].node.mainPageOgImage.file.url}
					twitterCover={this.props.data.data.edges[0].node.mainPageTwitterImage.file.url}
					fbappid={this.props.data.globalConfig.fbappid}
					twitterUser={this.props.data.globalConfig.twitterUser}
					slugs={{
						en: '/',
						fr: '/fr',
						de: '/de'
					}}
					location={this.props.location}
				/>
				<Home {...this.props} />
			</Layout>
		)
	}
}

export const pageQuery = graphql`
	query HomeEnQuery {
		site {
			siteMetadata {
				languages {
					defaultLangKey
					langs
				}
			}
		}
		mainNav: contentfulNav(
			name: { eq: "main-top-bar-nav" }
			node_locale: { eq: "en-US" }
		) {
			pages {
				slug
				nameInNav
				id
			}
		}
		mainNavLight: contentfulNav(
			name: { eq: "main-top-bar-nav-light" }
			node_locale: { eq: "en-US" }
		) {
			pages {
				slug
				nameInNav
				id
			}
		}
		mainFooterLight: contentfulFooterLight(
			name: { eq: "footer-light" }
			node_locale: { eq: "en-US" }
		) {
			title
			description
            rightsReservedLink
			ctaButton {
				title
				color
				size
				visible
				modal {
					internal {
						type
					}
					name
					title
					description
					tagLine
					emailPlaceholder
					agreementText
					linkToPage {
						slug
						title
					}
					submitButtonText
					emailErrorMessage
					tanksTitle
					thanksDescription
				}
			}
		}
		headerLight:  allContentfulHeaderLight(filter: {
			name: {eq: "header-light"}
			node_locale: { eq: "en-US" }
		}) {
			edges {
				node {
					name
					button {
						color
						size
						text
					}
					ctaButton {
						title
						color
						size
						visible
						modal {
							internal {
								type
							}
							name
							title
							description
							tagLine
							emailPlaceholder
							agreementText
							linkToPage {
								slug
								title
							}
							submitButtonText
							emailErrorMessage
							tanksTitle
							thanksDescription
						}
					}
				}
			}
		}
		globalConfig: contentfulGlobalConfig {
			id
			footer
			header
			fbappid
			twitterUser
		}
		posts: allContentfulPost(filter: { node_locale: { eq: "en-US" } }) {
			edges {
				node {
					id
					node_locale
					title {
						title
					}
					slug
				}
			}
		}

		data: allContentfulLandingPage(filter: { node_locale: { eq: "en-US" } }) {
			edges {
				node {
					mainPageMetaTitle
					mainPageMetaDescription
					mainPageOgDescription
					mainPageTwitterDescription
					mainPageMetaImage {
						file {
							url
						}
					}
					mainPageOgImage {
						file {
							url
						}
					}
					mainPageTwitterImage {
						file {
							url
						}
					}
					topScreenSubtitle
					topScreenTitle
					topScreenDescription
					appStore {
						title
						modal {
							name
							title
							description
							tagLine
							emailPlaceholder
							agreementText
							linkToPage {
								slug
								title
							}
							submitButtonText
							emailErrorMessage
							tanksTitle
							thanksDescription
						}
					}
					googlePlayButton {
						title
						modal {
							name
							title
							description
							tagLine
							emailPlaceholder
							agreementText
							linkToPage {
								slug
								title
							}
							submitButtonText
							emailErrorMessage
							tanksTitle
							thanksDescription
						}
					}
					buttonInvitation {
						color
						size
						text
					}
					buttonSecurityPaper {
						color
						size
						text
					}
					topScreenCta {
						title
						color
						size
						visible
						modal {
							internal {
								type
							}
							name
							title
							description
							tagLine
							emailPlaceholder
							agreementText
							linkToPage {
								slug
								title
							}
							submitButtonText
							emailErrorMessage
							tanksTitle
							thanksDescription
						}
					}
					topScreenImage {
						id
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
					videoScreenTitle
					videoScreenImage {
						id
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
					videoScreenVideoPoster {
						id
						file {
							url
							fileName
							contentType
						}
						fluid {
							base64
							src
							srcSet
							srcWebp
							srcSetWebp
						}
					}
					videoScreenVideo {
						file {
							url
							fileName
							contentType
						}
					}
					tabsTitle
					tabsDescription {
						childMarkdownRemark {
							html
						}
					}
					tabs {
						id
						tabTitle
						tabDescription {
							childMarkdownRemark {
								html
							}
						}
						tabImage {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						ctaButton {
							title
							color
							size
							visible
							modal {
								internal {
									type
								}
								name
								title
								description
								tagLine
								emailPlaceholder
								agreementText
								linkToPage {
									slug
									title
								}
								submitButtonText
								emailErrorMessage
								tanksTitle
								thanksDescription
							}
						}
					}
					greenScreenTitle {
						childMarkdownRemark {
							html
						}
					}
					greenScreenDescription {
						childMarkdownRemark {
							html
						}
					}
					greenScreenCtaLink {
						title
						color
						size
						visible
						ctaLink {
							slug
						}
					}
					greenScreenInfo {
						childMarkdownRemark {
							html
						}
					}
					greenScreenSliderTitleLeft
					greenScreenSliderListLeft
					greenScreenSliderTitleRight
					greenScreenSliderListRight
					greenScreenCards {
						title
						childContentfulLandingPageComponentsGreenCardsDescriptionTextNode {
							childMarkdownRemark {
								html
							}
						}
						icon {
							iconName
						}
					}
					doubleScreenLeft {
						childMarkdownRemark {
							html
						}
					}
					doubleScreenRight {
						childMarkdownRemark {
							html
						}
					}
					serverHeader {
						heading
						subtitleLong {
							childMarkdownRemark {
								html
							}
						}
					}
					ourServer {
						# tagLine
						# title
						# description
						slides {
							id
							file {
								url
								fileName
								contentType
							}
							fluid(maxWidth: 940) {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
					}
					verticalScrollScreen {
						verticalScreen {
							childMarkdownRemark {
								html
							}
						}
						image {
							id
							file {
								url
								contentType
							}
						}
						ctaButton {
							title
							color
							size
							visible
							modal {
								internal {
									type
								}
								name
								title
								description
								tagLine
								emailPlaceholder
								agreementText
								linkToPage {
									slug
									title
								}
								submitButtonText
								emailErrorMessage
								tanksTitle
								thanksDescription
							}
						}
					}
					whoWeAreTitle {
						heading
						subtitleLong {
							childMarkdownRemark {
								html
							}
						}
					}
					whoWeAreBody {
						titleLong {
							childMarkdownRemark {
								html
							}
						}
						subtitleLong {
							childMarkdownRemark {
								html
							}
						}
					}
					whoWeAreInfo {
						firstOfficeTitle
						firstOfficeDescription
						secondOfficeTitle
						secondOfficeDescription
						thirdOfficeTittle
						thirdOfficeDescription
						companyDescription {
							childMarkdownRemark {
								html
							}
						}
					}
					locationHeader {
						heading
						subtitleLong {
							childMarkdownRemark {
								html
							}
						}
					}
					locationBody {
						firstTitle
						firstDescription
						firstImage {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						secondTitle
						secondDescription
						secondImage {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						thirdTitle
						thirdDescription
						thirdImage {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
					}
					manifesto {
						iconsTitle
						info {
							childMarkdownRemark {
								html
							}
						}
					}
					comparisonTable {
						titleLong {
							childMarkdownRemark {
								html
							}
						}
						table {
							tableData
						}
						subDescription {
							childMarkdownRemark {
								html
							}
						}
						ctaButton {
							title
							color
							size
							visible
							modal {
								internal {
									type
								}
								name
								title
								description
								tagLine
								emailPlaceholder
								agreementText
								linkToPage {
									slug
									title
								}
								submitButtonText
								emailErrorMessage
								tanksTitle
								thanksDescription
							}
						}
					}
					benefits {
						titleLong {
							childMarkdownRemark {
								html
							}
						}
						benefitsImage {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						benefitsVideo {
							file {
								url
							}
						}
						ctaButton {
							title
							color
							size
							visible
							modal {
								internal {
									type
								}
								name
								title
								description
								tagLine
								emailPlaceholder
								agreementText
								linkToPage {
									slug
									title
								}
								submitButtonText
								emailErrorMessage
								tanksTitle
								thanksDescription
							}
						}
						benefitsItems {
							title {
								childMarkdownRemark {
									html
								}
							}
						}
					}
					useCases {
						title
						useCases {
							title
							description
							ctaButton {
								title
								color
								size
								visible
								modal {
									internal {
										type
									}
									name
									title
									description
									tagLine
									emailPlaceholder
									agreementText
									linkToPage {
										slug
										title
									}
									submitButtonText
									emailErrorMessage
									tanksTitle
									thanksDescription
								}
							}
						}
						firstColumnImages {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						secondColumnImages {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						thirdColumnImages {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						fourthColumnImages {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						fifthColumnImages {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
					}
					plans {
						titleLong {
							childMarkdownRemark {
								html
							}
						}
						individual {
							childMarkdownRemark {
								html
							}
						}
						individualIndependent {
							childMarkdownRemark {
								html
							}
						}
						enterprise {
							childMarkdownRemark {
								html
							}
						}
						individualCta {
							title
							color
							size
							visible
							modal {
								internal {
									type
								}
								name
								title
								description
								tagLine
								emailPlaceholder
								agreementText
								linkToPage {
									slug
									title
								}
								submitButtonText
								emailErrorMessage
								tanksTitle
								thanksDescription
							}
						}
						teamsCta {
							title
							color
							size
							visible
							modal {
								internal {
									type
								}
								name
								title
								description
								tagLine
								emailPlaceholder
								agreementText
								linkToPage {
									slug
									title
								}
								submitButtonText
								emailErrorMessage
								tanksTitle
								thanksDescription
							}
						}
						enterpriseCta {
							title
							color
							size
							visible
							modal {
								internal {
									type
								}
								name
								title
								description
								tagLine
								emailPlaceholder
								agreementText
								linkToPage {
									slug
									title
								}
								submitButtonText
								emailErrorMessage
								tanksTitle
								thanksDescription
							}
						}
					}
					blockChain {
						title
						image {
							id
							file {
								url
								fileName
								contentType
							}
							fluid {
								base64
								src
								srcSet
								srcWebp
								srcSetWebp
							}
						}
						ico
						icoCountdownTitle
						days
						hours
						minutes
						seconds
						infoLeft {
							childMarkdownRemark {
								html
							}
						}
						ctaLeft {
							title
							color
							size
							visible
							modal {
								internal {
									type
								}
								name
								title
								description
								tagLine
								emailPlaceholder
								agreementText
								linkToPage {
									slug
									title
								}
								submitButtonText
								emailErrorMessage
								tanksTitle
								thanksDescription
							}
						}
						infoRight {
							childMarkdownRemark {
								html
							}
						}
						ctaRight {
							title
							color
							size
							visible
							modal {
								internal {
									type
								}
								name
								title
								description
								tagLine
								emailPlaceholder
								agreementText
								linkToPage {
									slug
									title
								}
								submitButtonText
								emailErrorMessage
								tanksTitle
								thanksDescription
							}
						}
					}
				}
			}
		}
	}
`
