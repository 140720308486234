import React from 'react'

const TwitterHr = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g>
      <path
        d="M16,0.055C7.194,0.055,0.055,7.193,0.055,16c0,8.806,7.139,15.945,15.944,15.945c8.807,0,15.946-7.14,15.946-15.945
				C31.945,7.193,24.807,0.055,16,0.055 M16,30.031C8.25,30.031,1.968,23.749,1.968,16C1.968,8.25,8.25,1.968,16,1.968
				c7.749,0,14.032,6.282,14.032,14.032C30.031,23.749,23.748,30.031,16,30.031"
      />
      <path
        d="M16.08,13.416l0.031,0.524l-0.508-0.064c-1.846-0.247-3.457-1.081-4.826-2.481l-0.67-0.695l-0.172,0.514
				c-0.365,1.145-0.131,2.353,0.631,3.166c0.402,0.45,0.313,0.514-0.387,0.246c-0.244-0.085-0.457-0.149-0.479-0.117
				c-0.068,0.075,0.174,1.048,0.367,1.433c0.264,0.535,0.801,1.06,1.389,1.369l0.496,0.246l-0.588,0.01
				c-0.568,0-0.588,0.013-0.527,0.236c0.203,0.694,1.004,1.434,1.896,1.754l0.627,0.226l-0.547,0.343
				c-0.809,0.491-1.764,0.77-2.715,0.79C9.643,20.924,9.268,20.968,9.268,21c0,0.108,1.236,0.706,1.955,0.94
				c2.16,0.696,4.725,0.397,6.652-0.79c1.369-0.846,2.736-2.525,3.375-4.15c0.344-0.867,0.689-2.45,0.689-3.209
				c0-0.492,0.031-0.556,0.598-1.145c0.334-0.342,0.65-0.716,0.711-0.823c0.102-0.203,0.092-0.203-0.426-0.022
				c-0.861,0.322-0.984,0.278-0.559-0.202c0.314-0.343,0.689-0.962,0.689-1.145c0-0.032-0.15,0.021-0.322,0.118
				c-0.184,0.107-0.59,0.268-0.893,0.364l-0.547,0.182l-0.498-0.353c-0.273-0.193-0.658-0.407-0.861-0.471
				c-0.518-0.15-1.309-0.129-1.775,0.042C16.789,10.816,15.988,12.058,16.08,13.416C16.08,13.416,15.988,12.058,16.08,13.416"
      />
    </g>
  </svg>
)

export default TwitterHr
