import React, { PureComponent } from 'react';

import logoLabsUrl, { ReactComponent as LogoLabs } from '../../static/images/logo-labs.svg';
import logoUrl, { ReactComponent as Logo } from '../../static/images/logo-clean.svg';
import { ReactComponent as SkFrLogo } from '../../static/images/sk-fr-logo.svg';

import classNames from 'classnames'

import "./styles.scss"
import LearnMoreButton from '../LearnMoreButton/LearnMoreButton';

class CareersOurCompany extends PureComponent {
	render() {
		const {
			firstOfficeTitle,
			firstOfficeDescription,
			secondOfficeTitle,
			secondOfficeDescription,
			thirdOfficeTittle,
			thirdOfficeDescription,
			companyDescription,
			buttonText,
			buttonLink,
			mb
		} = this.props
		return (
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className={classNames('b-our-company', {
					'b-our-company--mb': mb
				})} >
					<div className="b-our-company__left">
						<div className="b-our-company__place">
							<div className="b-our-company__place-header">
								<Logo className="b-our-company__place-logo b-our-company__place-header--logo-big" />
								<span className="b-our-company__place-title" dangerouslySetInnerHTML={{
									__html: firstOfficeTitle,
								}} />
							</div>
							<span className="b-our-company__place-description t-paragraph t-paragraph--desctop-15 t-paragraph--gray">
								{firstOfficeDescription}
							</span>
						</div>
						<div className="b-our-company__place b-our-company__place--no-mb">
							<div className="b-our-company__place-header">
								<SkFrLogo className="b-our-company__place-logo" />
								<span className="b-our-company__place-title" dangerouslySetInnerHTML={{
									__html: secondOfficeTitle,
								}} />
							</div>
							<span className="b-our-company__place-description t-paragraph t-paragraph--desctop-15 t-paragraph--gray">
								{secondOfficeDescription}
							</span>
						</div>
						<div className="b-our-company__place b-our-company__place--no-mb" style={{display: "none"}}>
							<div className="b-our-company__place-header">
								<LogoLabs className="b-our-company__place-logo b-our-company__place-header--logo-big" />
								<span className="b-our-company__place-title" dangerouslySetInnerHTML={{
									__html: thirdOfficeTittle,
								}} />
							</div>
							<span className="b-our-company__place-description t-paragraph t-paragraph--desctop-15 t-paragraph--gray">
								{thirdOfficeDescription}
							</span>
						</div>
					</div>
					<div className="b-our-company__right">
						<div className="b-our-company__info t-text-block" dangerouslySetInnerHTML={{
							__html: companyDescription.childMarkdownRemark.html,
						}}/>
						{buttonText && buttonLink && <LearnMoreButton className="b-our-company__btn" text={buttonText} link={buttonLink} />}
					</div>
				</div>
			</section>
		)
	}
}

export default CareersOurCompany;
