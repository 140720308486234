import React from 'react';
import { useMobileDetect } from '../../hooks/useMobileDetect';
import { Button } from '../Buttons/Button';


const AuthButton = () => {
	const { isAndroid, isIos, isMobileOnly } = useMobileDetect();

	let href;

	if (isMobileOnly && isAndroid)
		href = 'https://play.google.com/store/apps/details?id=com.sharekey';
	else if (isMobileOnly && isIos)
		href = "https://apps.apple.com/ch/app/sharekey/id1460373909";
	else
		href = 'https://app.sharekey.com/';

	return (
		<a href={href} target='_blank' onClick={()=>window.plausible('Click Sign In/Sign Up', {props: {page: window.location.pathname}})}>
			<Button data={{
				"color": ["Blue"],
				"size": ["Regular"],
				"text": "SIGN IN / UP"
			}}
			/>
		</a>
	);
}

export default AuthButton;
