import React, {PureComponent} from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import CommonContext from '../ContextProvider/CommonContext'

import topArrowUrl, {
  ReactComponent as TopArrow,
} from '../../static/images/icons/top.svg'

import './styles.scss'

const ScrollTopContextWrapper = (props) => (
  <CommonContext.Consumer>
    {(context) => (
      <ScrollTop
        context={context}
        {...props} />
    )}
  </CommonContext.Consumer>
)
class ScrollTop extends PureComponent {
  render() {
    return (
      <AnchorLink
        href="#top"
        className={`scroll-top ${this.props.show && !this.props.context.state.isMobileNavOpen ? 'scroll-top__visible' : ''}`}
      >
        <TopArrow className="scroll-top__icon" />
      </AnchorLink>
    )
  }
}

export default ScrollTopContextWrapper
