import React, { PureComponent, Fragment } from 'react';

import './styles.scss'

import Modal from '../Modal/Modal'
import ModalSubscribe from '../ModalSubscribe/ModalSubscribe';

class CtaButton extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			modalShouldBeRender: false,
		};

		this.handleShow = this.handleShow.bind(this);
		this.handleHide = this.handleHide.bind(this);

	}

	handleShow(e) {
		if (!this.props.link) {
			e.prevantDefault
			this.setState({ showModal: true });
			document.querySelector('body').classList.add('body--fixed')
		}
	}

	handleHide() {
		this.setState({ showModal: false });
		document.querySelector('body').classList.remove('body--fixed')
	}

	render() {
		const props = this.props

		const modal = this.state.showModal ? (
			<Modal>
				<button className="modal__hide" onClick={this.handleHide} />
				<ModalSubscribe data={props.modal} close={() => this.handleHide()}/>
			</Modal>
		) : null;

		return (
			<Fragment>
				<a
					onClick={()=> {
						window.plausible(`Click Link with text: ${props.text}`, {props: {page: window.location.pathname}});
						this.handleShow();
					}}
					href={props.link || "#"}
					className={`b-button b-button--cta ${
						props.type ? `b-button--${props.type}` : ''
					} ${props.className ? props.className : ''}`}
				>
					{props.text}
				</a>
				{modal}
			</Fragment>
		)
	}
}


export default CtaButton
