import React, { PureComponent } from 'react';


import MapIconUrl, {
	ReactComponent as SwissMap,
} from '../../static/images/swissmap.svg'

import './styles.scss'
  
class CareersSwissMapArea extends PureComponent {
	render() {
		const { titleLong, subtitleLong} = this.props 
		return ( 
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-swiss-map-area">
					<div className="b-swiss-map-area__header">
						<h2 className="b-swiss-map-area__title t-title" dangerouslySetInnerHTML={{
							__html: titleLong.childMarkdownRemark.html,
						}} />
						<div className="b-swiss-map-area__subtitle t-label" dangerouslySetInnerHTML={{
							__html: subtitleLong.childMarkdownRemark.html,
						}}/>
					</div>
					<div className="b-swiss-map-area__map">
						<SwissMap />
					</div>
				</div>
			</section>
		)
	}
}
 
export default CareersSwissMapArea;