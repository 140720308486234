import React, { PureComponent } from 'react'
import './styles.scss'

class Burger extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isActive: false }
  }


  render() {
    return (
      <button
        className={`b-burger ${this.state.isActive ? 'b-burger--active' : ''}`}
        name="openMenu"
        onClick={this.props.onClick}
        role="presentation"
      >
        <div className="b-burger__box">
          <div className="b-burger__inner" />
        </div>
      </button>
    )
  }
}

export default Burger
