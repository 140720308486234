import React, { PureComponent } from 'react';

import { Link } from 'gatsby'

import "./styles.scss"

class LearnMoreButton extends PureComponent {
	render() { 
		return ( 
			<Link className={`b-learn-more ${this.props.className ? this.props.className : ''}`} to={this.props.link}>
				{this.props.text ? this.props.text : "learn more"}
			</Link>
		)
	}
}
 
export default LearnMoreButton;