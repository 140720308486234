import React, { Component } from 'react'
import { Link } from 'gatsby'

import './styles.scss'

class HeaderNavigation extends Component {
  render() {
    const { pages, langKey, type } = this.props

    return (
      <nav className={`b-navigation ${type === 'light' ? 'b-navigation--light' : '' }`}>
        <ul className="b-navigation__list">
          {pages.map(page => (
            <li className="b-navigation__item" key={page.id}>
              <Link
                to={`/${langKey !== 'en' ? `${langKey}/` : ''}${page.slug}`}
                className="b-navigation__link"
                activeClassName="b-navigation__link--active"
              >
                <span className="b-navigation__link-inner">
                  {page.nameInNav}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    )
  }
}

export default HeaderNavigation
