import React, { PureComponent } from 'react';
import GreenScreenCards from '../GreenScreenCards/GreenScreenCards'
import SecondaryButtonLink from '../../Buttons/SecondaryButtonLink'
import ResizableBox from 're-resizable'

import Img from 'gatsby-image'

import './styles.scss'

class GreenScreen extends PureComponent {
	state = {
		resizeWidth: -50,
		boxMinWidth: 1086,
	}

	componentDidMount() {
		let resizeWidth = document.querySelector('#resizeble-box').style.width.slice(0, -1) - 100;
		this.setState({
			boxMinWidth: this.box.offsetWidth
		})
	}

	handleResize(ref) {
		this.setState({
			resizeWidth: ref.style.width.slice(0, -1) - 100,
		})
	}

	render() {
		const {
			greenScreenTitle,
			greenScreenDescription,
			greenScreenInfo,
			greenScreenSliderTitleLeft,
			greenScreenSliderListLeft,
			greenScreenSliderTitleRight,
			greenScreenSliderListRight,
			greenScreenCards,
			greenScreenCtaLink,
		} = this.props
		return (
			<>
				<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet g-container--bg-green">
					<div className="b-green-screen">
						<div className="b-green-screen__row">
							<div className="b-green-screen__left">
								<div className="b-green-screen__left--title">
									<h2 className="b-green-screen__title t-title-middle t-title-middle--white" dangerouslySetInnerHTML={{
										__html: greenScreenTitle.childMarkdownRemark.html,
									}} />
								</div>
							</div>
						</div>
						<div className="b-green-screen__row">
							<div className="b-green-screen__left">
								<div className="b-green-screen__line"></div>
								<div className="b-green-screen__description t-paragraph t-paragraph--white" dangerouslySetInnerHTML={{
									__html: greenScreenDescription.childMarkdownRemark.html,
								}} />
								<SecondaryButtonLink data={greenScreenCtaLink} />
							</div>
							<div className="b-green-screen__right">
								<div className="b-green-screen__info-item t-text-block t-text-block--white t-text-block--title-m-20 " dangerouslySetInnerHTML={{
									__html: greenScreenInfo.childMarkdownRemark.html,
								}} />
							</div>
						</div>
					</div>
					<div className="b-green-screen-devider__box-wrapper">
						<div className="b-green-screen-devider"
							ref={item => this.box = item}>
							<div
								className="b-green-screen__row b-green-screen__content">
								<div className="b-green-screen__left">
									<h2 className="b-green-screen-devider__title t-title-middle t-title-middle--white" dangerouslySetInnerHTML={{
										__html: greenScreenSliderTitleLeft,
									}} />
									<ul className="b-green-screen-devider__list">
										{greenScreenSliderListLeft.map((item, key) => {
											return (
												<li key={key}
													className="b-green-screen-devider__list-item t-paragraph t-paragraph--white">
													{item}
												</li>
											)
										})}
									</ul>
								</div>
								<div className="b-green-screen__right">
									<h2 className="b-green-screen-devider__title t-title-middle t-title-middle--white" dangerouslySetInnerHTML={{
										__html: greenScreenSliderTitleRight,
									}} />
									<ul className="b-green-screen-devider__list">
										{greenScreenSliderListRight.map((item, key) => {
											return (
												<li key={key}
													className="b-green-screen-devider__list-item t-paragraph t-paragraph--white">
													{item}
												</li>
											)
										})}
									</ul>
								</div>
							</div>
							<ResizableBox
								className="b-green-screen-devider__wrapper"
								handleWrapperClass="b-green-screen-devider__devider"
								defaultSize={{ width: '50%' }}
								maxWidth="100%"
								minWidth="5"
								id="resizeble-box"
								onResize={(event, direction, ref) => this.handleResize(ref)}
								enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}>
								<div style={{ transform: `translateX(${this.state.resizeWidth}%)`, minWidth: this.state.boxMinWidth}}
									className="b-green-screen__row b-green-screen-devider__content">
									<div className="b-green-screen__left">
										<h2 className="b-green-screen-devider__title t-title-middle t-title-middle--white" dangerouslySetInnerHTML={{
											__html: greenScreenSliderTitleLeft,
										}} />
										<ul className="b-green-screen-devider__list">
											{greenScreenSliderListLeft.map((item, key) => {
												return (
													<li key={key}
														className="b-green-screen-devider__list-item t-paragraph t-paragraph--white">
														{item}
													</li>
												)
											})}
										</ul>
									</div>
									<div className="b-green-screen__right">
										<h2 className="b-green-screen-devider__title t-title-middle t-title-middle--white" dangerouslySetInnerHTML={{
											__html: greenScreenSliderTitleRight,
										}} />
										<ul className="b-green-screen-devider__list">
											{greenScreenSliderListRight.map((item, key) => {
												return (
													<li key={key}
														className="b-green-screen-devider__list-item t-paragraph t-paragraph--white">
														{item}
													</li>
												)
											})}
										</ul>
									</div>
								</div>
							</ResizableBox>
						</div>
					</div>
				</section>
				<div className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
					<GreenScreenCards cards={greenScreenCards} />
				</div>
			</>
		);
	}
}

export default GreenScreen;