import React, { Component } from 'react'
import { auth } from '../../firebase'
import 'isomorphic-unfetch'

import './styles.scss'

const CI_BRANCH = process.env.GATSBY_BRANCH
const DEPLOY_TRIGGER_URL =
  'https://us-central1-dev-sharekey-com.cloudfunctions.net/bitbucketCommit'

export default class extends Component {
  makePostRequest = () => {
    const branch = 'master'
    const author = `Staging <${this.props.email}>`
    const message = `${
      this.props.email
    } is deploying new content from Staging to Production.`
    const postUrl = `${DEPLOY_TRIGGER_URL}/?branch=master&author=${author}&message=${message}`

    try {
      fetch(postUrl, {
        method: 'POST',
        mode: 'no-cors',
      })
    } catch (e) {
      throw e
    }
  }

  pushToProduction = () => {
    const confirm = prompt('If you sure, please type "deploy" and click OK', '')

    if (confirm === null || confirm === '') {
      console.log('User cancelled the deploy.')
    } else if (confirm === 'deploy') {
      console.log('starting deploy')
      this.makePostRequest()
    }
  }

  render() {
    const { email } = this.props

    return (
      <div className="auth-bar">
        <span className="auth-bar__env">
          ENV: <strong>{CI_BRANCH}</strong>
        </span>
        <span className="auth-bar__user">Logged as {email}</span>

        <button
          type="button"
          className="button button--cta"
          onClick={auth.doSignOut}
        >
          Sign Out
        </button>
        {CI_BRANCH === 'staging' && (
          <button
            type="button"
            className="button button--cta auth-bar__push"
            onClick={this.pushToProduction}
          >
            Push to Production
          </button>
        )}
      </div>
    )
  }
}
