import React, { PureComponent, Fragment } from 'react';

import classNames from 'classnames'

import './styles.scss'

import Modal from '../Modal/Modal'
import ModalSubscribe from '../ModalSubscribe/ModalSubscribe';

class MainButton extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			modalShouldBeRender: false,
			scrollTopPosition: 0,
			selectorToFixLayout: 'body',
		};

		this.handleShow = this.handleShow.bind(this);
		this.handleHide = this.handleHide.bind(this);

	}
	
	componentDidMount() {
		this.setState({
			selectorToFixLayout: "ontouchstart" in document.documentElement ? '#___gatsby': 'body'
		})
	}

	handleShow() {
		let selector = "ontouchstart" in document.documentElement;
		let scrollTopPosition = document.documentElement.scrollTop || document.body.scrollTop;
		
		this.setState({
			scrollTopPosition: scrollTopPosition
		});
		
		document.querySelector(this.state.selectorToFixLayout).style.top = (- scrollTopPosition) + 'px';
		
		this.setState({ showModal: true });
		
		document.querySelector(this.state.selectorToFixLayout).classList.add('body--fixed');
	}

	handleHide() {
		this.setState({ showModal: false });
		document.querySelector(this.state.selectorToFixLayout).classList.remove('body--fixed')
		document.documentElement.scrollTop = document.body.scrollTop = this.state.scrollTopPosition;
	}

	render() {
		const { modal, title } = this.props.data;
		const { className } = this.props;
		const size = this.props.data.size[0].toLowerCase();
		const color = this.props.data.color[0].toLowerCase();
		const modalEl = this.state.showModal ? (
			<Modal color={modal.internal.type === 'ContentfulModalKeepInTouch' ? "white" : '' }>
				<button className="modal__hide" onClick={this.handleHide} />
				<ModalSubscribe data={modal} close={() => this.handleHide()} />
			</Modal>
		) : null;

		return (
			<Fragment>
				<button
					onClick={this.handleShow}
					className={classNames('b-button b-button--main-cta', {
						[`b-button--${size}`]: size,
						[`b-button--${color}`]: color,
						className: className
					})}>
					{title}
				</button>
				{modalEl}
			</Fragment>
		)
	}
}

export default MainButton;
