import React, { PureComponent } from "react";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import classNames from "classnames";
import Swipe from "react-easy-swipe";

import CommonContext from "../ContextProvider/CommonContext";
import Burger from "../Burger/Burger";
import { ReactComponent as Logo } from "../../static/images/logo.svg";
import { ReactComponent as LogoCareers } from "../../static/images/logo_careers.svg";
import AuthButton from "../AuthButton/AuthButton";
import { ClientOnly } from "../ClientOnly/ClientOnly";

import "./styles.scss";


const HeaderLightContextWrapper = (props) => (
	<CommonContext.Consumer>
		{ (context) => (
			<HeaderLight
				context={ context }
				{ ...props } />
		) }
	</CommonContext.Consumer>
);

class HeaderLight extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeLinkKey: "",
			isMobileMenuOpen: false,
			mobileMenuPosition: -100,
			scrollTopPosition: 0,
			navForPage: "",
			isSafari: false,
			isMobileMenu: false,
			isNavSwiping: false,
			resize: false,
			sections: [{
				name: "Privacy & Security",
				link: "#privacy"
			}, {
				name: "Features",
				link: "#features"
			}, {
				name: "About Us",
				link: "#whoweare"
			}
			]
		};
		this.onSwipeMove = this.onSwipeMove.bind(this);
		this.onSwipeEnd = this.onSwipeEnd.bind(this);
	}

	componentWillMount() {
		if (typeof window !== "undefined" && window.location.pathname.indexOf("careers") > 0 || this.props.showCarrersNav) {
			this.setState({
				navForPage: "careers"
			});
		} else {
			this.setState({
				navForPage: "home"
			});
		}
	}

	componentDidMount() {
		let isSafari = navigator.vendor && navigator.vendor.indexOf("Apple") > -1 &&
			navigator.userAgent && !navigator.userAgent.match("CriOS");

		if (window && window.innerWidth > 780) {
			this.setState({
				mobileMenuPosition: 0,
				isMobileMenu: false
			});
		} else {
			this.setState({
				mobileMenuPosition: -100,
				isMobileMenu: true
			});
		}

		if (isSafari) {
			this.setState({
				isSafari: true
			});
		}
	}

	onSwipeMove(position) {
		if (position.x < 0) {
			this.setState({
				mobileMenuPosition: position.x / 10,
				isNavSwiping: true
			});
		}
	}

	onSwipeEnd() {
		this.setState({
			isNavSwiping: false
		});
		if (this.state.mobileMenuPosition < -10) {
			this.linkClickHandler();
		} else {
			this.setState({
				mobileMenuPosition: 0
			});
		}
	}

	linkClickHandler() {
		const { isMobileMenuOpen, scrollTopPosition } = this.state;
		const { context } = this.props;

		if (isMobileMenuOpen) {
			context.state.changeIsMobileNavOpen(false);
			this.setState({
				mobileMenuPosition: -100,
				isMobileMenuOpen: !isMobileMenuOpen
			});
		}
		document.querySelector("body").classList.remove("body--fixed");
		document.documentElement.scrollTop = document.body.scrollTop = scrollTopPosition;
	}

	openMenuHandle() {
		this.props.context.state.changeIsMobileNavOpen(true);
		let scrollTopPosition = document.documentElement.scrollTop || document.body.scrollTop;
		this.setState({
			scrollTopPosition: scrollTopPosition
		});

		document.querySelector("body").style.top = (-scrollTopPosition) + "px";

		this.setState({
			mobileMenuPosition: 0,
			isMobileMenuOpen: !this.state.isMobileMenuOpen
		});

		document.querySelector("body").classList.add("body--fixed");
	}

	getSwipedMenuPosition() {
		if (this.state.isMobileMenuOpen) {
			return ({
				"transform": `translateX(${ this.state.mobileMenuPosition }%)`
			});
		} else {
			return ({});
		}
	}

	render() {
		const {
			sections,
			navForPage,
			isMobileMenuOpen,
			isSafari,
			isNavSwiping,
			isMobileMenu
		} = this.state;
		const { url, context: { state }, data } = this.props;
		const { headerLightPrealpha, headerLight } = data;
		const { activeHomeSection } = state;
		const specificPageSlug = headerLightPrealpha
			&& headerLight.edges[0].node.useOnSpecificPage;

		const getUrl = () => typeof window !== "undefined" && window.location.pathname === "/";
		const isWindowDefine = typeof window !== "undefined" && window.location.pathname.indexOf(specificPageSlug) > -1;

		return (
			<header id="header" className={ `s-header ${ this.props.isFixed ? "s-header--fixed" : "" }` }>
				<div className="g-container g-container--header g-container--header-light">
					<Burger
						className="s-header__burger"
						onClick={ () => this.openMenuHandle() }
					/>
					<div className="g-item--header-left">
						{ navForPage === "home" &&
						<Link to="/" className="s-header__logo-link"><Logo className="s-header__logo"/></Link> }
						{ navForPage === "careers" && <Link to="/careers" className="s-header__logo-link"><LogoCareers
							className="s-header__logo"/></Link> }
					</div>
					<Swipe
						className="s-header__nav-wrapper"
						onSwipeStart={ this.onSwipeStart }
						onSwipeMove={ this.onSwipeMove }
						onSwipeEnd={ this.onSwipeEnd }>
						<div
							style={ this.getSwipedMenuPosition() }
							ref={ (menu) => {
								this.menu = menu;
							} }
							className={ classNames("g-item--header-right s-header__menu", {
								"s-header__menu--active": isMobileMenuOpen,
								"s-header__menu--safari": isSafari,
								"s-header__menu--onswipe": isNavSwiping,
								"s-header__menu--fixed-position": !isMobileMenuOpen && !isNavSwiping
							}) }>
							<button className="modal__hide" onClick={() => this.linkClickHandler()} />
							<div className="b-navigation__mobile-wrapper">
								<nav className="b-navigation b-navigation--light">
									<ul className="b-navigation__list">
										{ navForPage === "home" && url === "/" && sections.map((section, key) => (
											<li className="b-navigation__item" key={ key }>
												{isMobileMenu && <AnchorLink
													href={ section.link }
													onClick={ () => this.linkClickHandler() }
													className={classNames("b-navigation__link", {
														"b-navigation__link--active": `#${ activeHomeSection }` === section.link
													}) }>
													<span className="b-navigation__link-inner">
														{ section.name }
													</span>
												</AnchorLink> }
												{!isMobileMenu && <AnchorLink
													href={ section.link }
													className={classNames("b-navigation__link", {
														"b-navigation__link--active": `#${ activeHomeSection }` === section.link
													}) }>
													<span className="b-navigation__link-inner">
														{section.name}
													</span>
												</AnchorLink> }
											</li>
										)) }

										{navForPage !== "home" || url !== '/'
											? <li className="b-navigation__item">
												<Link
													to='/'
													className={ classNames("b-navigation__link", {
														"b-navigation__link--active": getUrl()
													}) }
													onClick={(e) => this.linkClickHandler(e)}>
													<span className="b-navigation__link-inner">
														Home
													</span>
												</Link>
											</li>
											: null
										}

										<li className="b-navigation__item">
											<Link
												to='/careers'
												className={ classNames("b-navigation__link") }
												onClick={ (e) => window.plausible('We are Hiring', {props: {page: window.location.pathname}}) && this.linkClickHandler(e) }
												activeClassName="b-navigation__link--active">

												<span className="b-navigation__link-inner">
													We are Hiring
												</span>
											</Link>
										</li>
										{navForPage === "home" || url.includes('download')
											? <li className="b-navigation__item">
												<Link
													to='/support'
													state={{ prevPath: url }}
													className={ classNames("b-navigation__link") }
													onClick={ (e) => window.plausible('Support Button In Header', {props: {page: window.location.pathname}}) && this.linkClickHandler(e) }
													activeClassName="b-navigation__link--active"
												>
													<span className="b-navigation__link-inner">
														Support
													</span>
												</Link>
											</li>
											: null
										}
									</ul>
								</nav>
								{headerLight &&
									<ClientOnly>
										<AuthButton/>
									</ClientOnly>
								}
							</div>
						</div>
					</Swipe>
					{headerLight &&
						<ClientOnly>
							<AuthButton/>
						</ClientOnly>
					}
				</div>
			</header>
		);
	}
}

export default HeaderLightContextWrapper;
