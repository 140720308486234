import React from 'react'

const SharekeyHr = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M16,0.055C7.194,0.055,0.055,7.193,0.055,16S7.194,31.945,16,31.945c8.806,0,15.945-7.139,15.945-15.945	S24.807,0.055,16,0.055 M16,30.031C8.25,30.031,1.968,23.749,1.968,16S8.25,1.969,16,1.969c7.75,0,14.031,6.282,14.031,14.031	S23.75,30.031,16,30.031" />
    <path d="M18.437,21.437l-0.403,0.514c-0.749,1.021-2.19,1.249-3.22,0.506l-0.196-0.141c-1.03-0.742-1.258-2.171-0.51-3.191	c0.124-0.169,0.248-0.339,0.371-0.507l0.647-0.885c-0.218,0.044-0.448,0.101-0.689,0.173c-0.511,0.153-0.992,0.268-1.448,0.344	c-1.309,0.217-2.553-0.664-2.782-1.97c-0.112-0.644,0.043-1.305,0.43-1.827l2.931-3.914c-0.011,0.015-0.021,0.03-0.032,0.046	c0.014-0.021,0.028-0.041,0.043-0.059l-0.034,0.046l0.347-0.474c0.787-1.074,2.304-1.313,3.389-0.532	c1.083,0.781,1.323,2.285,0.536,3.359l-0.941,1.234c0.127-0.023,0.256-0.051,0.39-0.086c0.768-0.197,1.485-0.293,2.153-0.291	c1.327,0.005,2.408,1.083,2.413,2.406c0.002,0.518-0.164,1.022-0.473,1.434l-2.323,3.102L18.437,21.437z" />
  </svg>
)

export default SharekeyHr
