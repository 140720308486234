import React, { PureComponent } from 'react';
import classNames from 'classnames'

import phone_bg from './phone_bg.png'
import Observer from 'react-intersection-observer';
import SecondaryButton from '../../Buttons/SecondaryButton';

import '../../../static/images/screen.jpg'
import './styles.scss'

class VarticalScreens extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeScreen: 0,
			onOrientationChange: true,
			isMobile: !(typeof window !== 'undefined' && window.innerWidth > 1020),
			shouldVideoRender: false
		}
		this.video = [];
		this.mobileVideo = [];
	}

	componentDidMount() {
		if (typeof window !== 'undefined' && 'onorientationchange' in window) {
			window.addEventListener('orientationchange', this.handleOrientationChange)
			window.addEventListener('resize', this.handleWindowSizeChange);
		}
		document.addEventListener('DOMContentLoaded', this.startVideoUpload(), false);
	}

	handleWindowSizeChange = () => {
		this.setState({ isMobile: !(typeof window !== 'undefined' && window.innerWidth > 1020) });
	}

	componentWillUnmount() {
		if (typeof window !== 'undefined' && 'onorientationchange' in window) {
			window.removeEventListener('orientationchange', this.handleOrientationChange)
			window.removeEventListener('resize', this.handleWindowSizeChange);
		}
		document.removeEventListener('DOMContentLoaded', this.startVideoUpload(), false);
	}

	handleOrientationChange = () => {
		this.forceUpdate();
	}

	handleChange(inView, key) {
		if (inView) {
			this.setState({ activeScreen: key })
			this.video[key] && typeof this.video[key].play() !== 'undefined' && this.video[key].play()
			this.video[key] && (this.video[key].playbackRate = 1.3)
		} else {
			this.video[key] && !this.video[key].paused && this.video[key].currentTime > 0 && this.video[key].pause()
		}

	}
	handleChangeOnMobile(inView, key) {
		if (inView) {
			this.mobileVideo[key] && this.mobileVideo[key].play()
			this.mobileVideo[key] && (this.mobileVideo[key].playbackRate = 1.3)
		} else {
			this.mobileVideo[key] && !this.mobileVideo[key].paused && this.mobileVideo[key].currentTime > 0 && this.mobileVideo[key].pause()
		}

	}
	startVideoUpload() {
		this.setState({
			shouldVideoRender: true,
		})
	}
	render() {
		const { screens } = this.props
		const { activeScreen, isMobile, shouldVideoRender } = this.state
		let renderVideo = (screen, key) => {
			return (
				<video
					key={key}
					// src={typeof window !== 'undefined' && window.devicePixelRatio > 1
					// 	? 'https:' + screen.imageRetina.file.url
					// 	: 'https:' + screen.image.file.url}
					src={'https:' + screen.image.file.url}
					className={classNames('b-vertical-screens__video b-vertical-screens__video--lg', {
						'b-vertical-screens__video--active': key == activeScreen,
						'b-vertical-screens__video--big': key == 4,
					})}
					preload="auto"
					loop={true}
					muted
					autoPlay
					playsInline
					ref={el => this.mobileVideo[key] = el}
				/>
			)
		}

		return (
			<div className="b-vertical-screens">
				<div className="b-vertical-screens__left">
					{screens.map((screen, key) => {
						return (
							!isMobile
							? <Observer
								key={key}
								onChange={inView => this.handleChange(inView, key)}
								threshold={[0.5]}
								className="b-vertical-screens__observer"
							>
								<div className="b-vertical-screens__screen-wrapper">
									<div className="b-vertical-screens__screen t-text-block t-text-block--title-d-33" dangerouslySetInnerHTML={{
										__html: screen.verticalScreen.childMarkdownRemark.html,
									}} />
									<SecondaryButton data={screen.ctaButton} />
								</div>
								<div className='image-container'>
									<img key={key} src={'https:' + screen.image.file.url} className={classNames(
										'b-vertical-screens__video', {
											// 'b-vertical-screens__video--active': key == activeScreen,
											// 'b-vertical-screens__video--big': key == 4,
										})}
									/>
								</div>
							</Observer>
							: <div className="b-vertical-screens__screen-wrapper" key={key}>
									<div className="b-vertical-screens__screen t-text-block t-text-block--title-d-33" dangerouslySetInnerHTML={{
									__html: screen.verticalScreen.childMarkdownRemark.html,
								}} />
								<Observer
									key={key}
									onChange={inView => this.handleChangeOnMobile(inView, key)}
									className="b-vertical-screens__mobile-observer"
									threshold={[0]}>
									<div className="b-vertical-screens__videos b-vertical-screens__videos--lg">
										{/* {key != 4 && <img src={phone_bg} alt=""
											className="b-vertical-screens__phone-bg b-vertical-screens__phone-bg--lg" />} */}
										{/* {shouldVideoRender && renderVideo(screen, key)} */}

											<img key={key} src={'https:' + screen.image.file.url} className={classNames(
												'b-vertical-screens__video','b-vertical-screens__video--active', {
													// 'b-vertical-screens__video--big': key == 4,
												})}
											/>
									</div>
								</Observer>
								<SecondaryButton data={screen.ctaButton} />
							</div>
						)
					})}
				</div>

				<div className="b-vertical-screens__right">
					{/* {!isMobile ? */}
						<div className="b-vertical-screens__videos">
							{/* {activeScreen != 4 && <img src={phone_bg} alt=""
								className="b-vertical-screens__phone-bg"/>} */}
							{screens.map((screen, key) => {
								return (
									// shouldVideoRender && <video
									// 	key={key}
									// 	src={typeof window !== 'undefined' && window.devicePixelRatio > 1
									// 		? 'https:' + screen.imageRetina.file.url
									// 		: 'https:' + screen.image.file.url}
									// 	className={classNames('b-vertical-screens__video', {
									// 		'b-vertical-screens__video--active': key == activeScreen,
									// 		'b-vertical-screens__video--big': key == 4,
									// 	})}
									// 	preload="auto"
									// 	loop={true}
									// 	muted
									// 	playsInline
									// 	ref={el => this.video[key] = el}
									// />
									<img key={key} src={'https:' + screen.image.file.url} className={classNames(
										'b-vertical-screens__video', {
											'b-vertical-screens__video--active': key == activeScreen,
											// 'b-vertical-screens__video--big': key == 4,
										}
										)}
									/>
								)
							})}
						</div>
						{/* : null } */}
					{/* <div className="b-vertical-screens__nav">
						{screens.map((screen, key) => {
							return (
								<div
									key={key}
									className={classNames('b-vertical-screens__nav-item',
										{ 'b-vertical-screens__nav-item--active': key == activeScreen })}
								>
									<img key={key} src={'https:' + screen.image.file.url} className={classNames(
										'b-vertical-screens__video', {
											'b-vertical-screens__video--active': key == activeScreen,
											'b-vertical-screens__video--big': key == 4,
										}
									)}/>
								</div>
							)
						})}
					</div> */}
				</div>
			</div>
		)
	}
}

export default VarticalScreens;