import React, { PureComponent } from 'react';

import { LINKS } from '../../constants/links';
import { ReactComponent as HightTechPark } from '../../static/images/icons/logo-htp.svg';
import { ReactComponent as FranceDigitale } from '../../static/images/icons/fd-logo.svg';
import { ReactComponent as ValleyIcon } from '../../static/images/icons/_crypto_valley_color.svg';
import { ReactComponent as LabsIcon } from '../../static/images/icons/crypto_labs.svg';

import './styles.scss'

class CareersManifesto extends PureComponent {
	render() {
		let { iconsTitle, info } = this.props
		return (
			<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
				<div className="b-manifesto">
					<div className="b-manifesto__left">
						<div className="b-manifesto__icons">
							<span className="b-manifesto__icons-title">
								{iconsTitle}
							</span>
							<div className="b-manifesto-container">
								<div className="b-manifesto__icon">
									<a href="https://cryptovalley.swiss"
										target="_blank"
										rel="noopener"
										className="b-manifesto__link"
										title="Crypto Valley"
										onClick={()=>window.plausible('Click Crypto Valley Link', {props: {page: window.location.pathname}})}
									>
										<ValleyIcon className="b-manifesto__icon-valley"/>
									</a>
								</div>
								<div className="b-manifesto__icon">
									<a href={LINKS.CVVC}
										target="_blank"
										rel="noopener"
										className="b-manifesto__link"
										title="Crypto Valley Labs"
										onClick={()=>window.plausible('Click Crypto Valley Labs Link', {props: {page: window.location.pathname}})}
									>
										<LabsIcon className="b-manifesto__icon-labs"/>
									</a>
								</div>
								<div className="b-manifesto__icon">
									<a
										href="https://francedigitale.org/"
										target="_blank"
										rel="noopener"
										className="b-manifesto__link"
										title="France digitale"
										onClick={()=>window.plausible('Click France digitale Link', {props: {page: window.location.pathname}})}
									>
										<FranceDigitale className='b-manifesto__icon-fd' />
									</a>
								</div>
								<div className="b-manifesto__icon" style={{display: "none"}}>
									<a href={LINKS.HTP}
										target="_blank"
										rel="noopener"
										className="b-manifesto__link"
										title="High Tech Park"
										onClick={()=>window.plausible('Click Open High Tech Park Link', {props: {page: window.location.pathname}})}
									>
										<HightTechPark className="b-manifesto__icon-htp"/>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="b-manifesto__right">
						<div className="b-manifesto__info t-text-block" dangerouslySetInnerHTML={{
							__html: info.childMarkdownRemark.html,
						}}>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default CareersManifesto;
