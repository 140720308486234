import React, {PureComponent} from 'react'
import Img from 'gatsby-image';
import { Link } from "gatsby";

import Observer from 'react-intersection-observer';
import CommonContext from '../components/ContextProvider/CommonContext';

import HomeTabs from '../components/Home/HomeTabs/HomeTabs';
import VerticalScreens from '../components/Home/VerticalScreens/VerticalScreens';
import HomeSlider from '../components/Home/HomeSlider/HomeSlider';
import TextBanner from '../components/TextBanner/TextBanner';
import CareersSwissMapArea from '../components/CareersSwissMapArea/CareersSwissMapArea';
import CareersOurCompany from '../components/CareersOurCompany/CareersOurCompany';
import LocationSpoiler from '../components/LocationSpoiler/LocationSpoiler';
import CareersManifesto from '../components/CareersManifesto/CareersManifesto';
import DoubleScreen from '../components/Home/DoubleScreen/DoubleScreen';
import GreenScreen from '../components/Home/GreenScreen/GreenScreen';
import { AppStoreIcon } from "../components/Icons/AppStoreIcon";
import { GooglePlayIcon } from "../components/Icons/GooglePlayIcon";
import { LaptopIcon } from "../components/Icons/LaptopIcon";

import { Button } from "../components/Buttons/Button";

import './home.scss';
import '../static/images/mountain.jpg';
import '../static/images/mountain-video.png';


const HomePageContextWrapper = (props) => (
	<CommonContext.Consumer>
		{(context) => (
			<HomePage
				context={context}
				{...props} />
		)}
	</CommonContext.Consumer>
)

class HomePage extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			parallaxTopScroll: 0,
			parallaxVideoScroll: 65,
			paralaxVideoPosition: 0,
			activeSection: '',
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)

		this.setState({
			paralaxVideoPosition: this.imageToParallax.getBoundingClientRect().top
		})
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	handleSectionInView(inView, section) {
		if (inView) {
			this.props.context.state.changeActiveSection(section)

		} else if (!inView && this.props.context.state.activeHomeSection === section) {
			this.props.context.state.changeActiveSection('')
		}
	}

	handleScroll = () => {
		let scrollTop = document.documentElement.scrollTop || window.pageYOffset
		if (scrollTop / -10 > -150 && window.innerWidth > 780) {
			this.setState({
				parallaxTopScroll: scrollTop / -7
			})
		} else {
			this.setState({
				parallaxTopScroll: 0
			})
		}
	}

	fixSafari() {
		let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
			navigator.userAgent && !navigator.userAgent.match('CriOS');
		let scrollPosition = document.documentElement.scrollTop || window.pageYOffset
		if (isSafari) {
			setTimeout(() => {
				window.scrollTo(0, scrollPosition - 1)
				window.scrollTo(0, scrollPosition)
			}, 300);
		}
	}

	render() {
		const {
			topScreenTitle,
			topScreenSubtitle,
			topScreenDescription,
			topScreenImage,
			videoScreenTitle,
			videoScreenImage,
			tabsTitle,
			tabsDescription,
			tabs,
			doubleScreenLeft,
			doubleScreenRight,
			verticalScrollScreen,
			whoWeAreTitle,
			whoWeAreBody,
			whoWeAreInfo,
			locationHeader,
			locationBody,
			ourServer,
			serverHeader,
			manifesto,
			buttonInvitation,
			buttonSecurityPaper,
		} = this.props.data.data.edges[0].node;
		const { location } = this.props;

		const sizeToTopParallax = { transform: `translateY(${this.state.parallaxTopScroll}px)` };
		const sizeToVideoParallax = { transform: `translateY(${this.state.parallaxVideoScroll}px)` };
		const arrayOfDescriptions = topScreenDescription.split('.');
		const topScreenDescriptionTransformed = arrayOfDescriptions.map((sentence, i) => {
			const dot = i === arrayOfDescriptions.length - 1 ? '' : '.';
			return <p className='b-top-screen__description' key={i}>{`${sentence}${dot}`}</p>;
		});

		const transformedTabsTitle = tabsTitle.split(',').map((item, i) => {
			return <h2 className="b-tabs-screen__title t-title-middle t-title-middle--dark" key={i}>
				{`${item}${i === 0 ? ',' : ''}`}
			</h2>;
		});

		return (
			<div className="scroll">
				<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet g-container">
					<div className="b-top-screen">
						<div className="b-top-screen__background-wrapper">
							<Img className="b-top-screen__background"
								fluid={topScreenImage.fluid}
								style={sizeToTopParallax}
								onLoad={() => this.fixSafari()}
							/>
						</div>
						<div className="b-top-screen__info">
							<span className="b-top-screen__subtitle">{topScreenSubtitle}</span>
							<h1 className="b-top-screen__title t-title">{topScreenTitle}</h1>
							<div className="b-top-screen__line" />
							{topScreenDescriptionTransformed}
							<Link
								to='/support'
								onClick={()=>window.plausible('Clicked Support Button Home', {props: {page: window.location.pathname}})}
							>
								<Button data={buttonInvitation}>
									Support
								</Button>
							</Link>
						</div>
						<div className="b-top-screen__markets">
							<a
								href='https://apps.apple.com/ch/app/sharekey/id1460373909'
								target='_blank'
								className='b-top-screen__markets-link b-top-screen__markets-link-app-store'
								title='Download from app store'
								onClick={()=>window.plausible('Click App Store Download', {props: {page: window.location.pathname}})}
							>
								<AppStoreIcon />
							</a>
							<a
								href='https://play.google.com/store/apps/details?id=com.sharekey'
								target='_blank'
								className='b-top-screen__markets-link b-top-screen__markets-link-google-play'
								title='Download from play market'
								onClick={()=>window.plausible('Click GooglePlay Download', {props: {page: window.location.pathname}})}
							>
								<GooglePlayIcon />
							</a>
							<a
								href='https://sharekey.com/download/'
								target='_blank'
								className='b-top-screen__markets-link b-top-screen__markets-link-desktop'
								title='Download desktop application'
								onClick={()=>window.plausible('Click Download desktop Application Button', {props: {page: window.location.pathname}})}
							>
								<span className='icon'>
									<LaptopIcon />
								</span>
								<div className='text-block'>
									<span className='text text-secondary'>Download</span>
									<span className='text'>Desktop App</span>
								</div>
							</a>
						</div>
					</div>
				</section>
				<section className="g-container g-container--double-pad g-container--hidden">
					<div className="b-video-screen" ref={node => { this.imageToParallax = node }}>
						<div className="b-video-screen__background-wrapper">
							<Img className="b-video-screen__background"
								fluid={videoScreenImage.fluid}
								style={sizeToVideoParallax} />
						</div>
						<h2 className="b-video-screen__title">{videoScreenTitle}</h2>
						<div className="b-video-screen__line" />
					</div>
				</section>
				<Observer
					onChange={inView => this.handleSectionInView(inView, 'privacy')}>
					<div className="section-anchor" id="privacy" />
					<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
						<div className="b-tabs-screen">
							<div className="b-tabs-screen__info">
								{transformedTabsTitle}
								<div className="b-tabs-screen__line" />
								<div className="video-container">
								<iframe
									src="https://player.vimeo.com/video/516660893"
									frameBorder="0"
									allow="autoplay; fullscreen; picture-in-picture"
									allowFullScreen
								/>
								</div>
								<div className="b-tabs-screen__description t-paragraph t-paragraph--gray" dangerouslySetInnerHTML={{
									__html: tabsDescription.childMarkdownRemark.html,
								}} />
							</div>
							<HomeTabs
								tabs={tabs}
								location={location}
								buttonSecurityPaper={buttonSecurityPaper}
							/>
						</div>
					</section>
					{/* TODO: refactor data flow with contentful model */}
					<GreenScreen {...this.props.data.data.edges[0].node} />
				</Observer>
				<TextBanner {...serverHeader} />
				<HomeSlider {...ourServer}/>
				<Observer
					onChange={inView => this.handleSectionInView(inView, 'features')} >
					<div className="section-anchor" id="features" />
					<DoubleScreen leftText={doubleScreenLeft} rightText={doubleScreenRight}/>
					<section className="g-container g-container--double-pad">
						<VerticalScreens screens={verticalScrollScreen} />
					</section>
				</Observer>
				<Observer onChange={inView => this.handleSectionInView(inView, 'whoweare')} >
					<div className="section-anchor" id="whoweare" />
					<TextBanner {...whoWeAreTitle} />
					<CareersSwissMapArea {...whoWeAreBody} />
					<CareersOurCompany {...whoWeAreInfo} mb={true}/>
					<CareersManifesto {...manifesto} />
					<TextBanner {...locationHeader} />
					<LocationSpoiler {...locationBody}/>
				</Observer>
			</div>
		)
	}
}

export default HomePageContextWrapper;
