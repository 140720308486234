const logo = require('../static/images/logo.svg')

module.exports = {
  langs: ['en', 'fr'],
  defaultLangKey: 'en',
  siteUrl: 'https://sharekey.com',
  pathPrefix: '',
  siteTitle: 'Sharekey',
  siteDescription: 'Lorem ipsum dolor sit (160 - 320 characters)',
  siteLogo: logo,
  siteTitleAlt: null,
  userTwitter: '',
  siteFBAppID: '',
  noIndex: true,
}
