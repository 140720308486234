import React, { PureComponent, Fragment } from 'react';

import ModalSupport from '../../ModalSupport';
import Modal from '../../Modal/Modal';

class SecurityPaperButton extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			modalShouldBeRender: false,
		}
	}

	handleShow = () => {
		this.setState({ showModal: true });
		document.querySelector('body').classList.add('body--fixed')
	}

	handleHide = () => {
		this.setState({ showModal: false });
		document.querySelector('body').classList.remove('body--fixed')
	}

	render() {
		const { onClick, text, className, location } = this.props
		const { showModal } = this.state;

		const modal = showModal
			&& <Modal color="white">
				<button className="modal__hide" onClick={this.handleHide}></button>
				<ModalSupport
					subject='Request Security Paper'
					location={location}
					handleClose={this.handleHide}
				/>
			</Modal>;
		const buttonClassName = `b-button b-button--main-cta b-button--regular b-button--blue ${className ? className : ''}`;

		return (
			<Fragment>
				<button
					className={buttonClassName}
					onClick={this.handleShow}
				>
					{text}
				</button>
				{modal}
			</Fragment>
		)
	}
}

export default SecurityPaperButton;
