import React from 'react'
import PropTypes from 'prop-types'
import isDevOrLive from '../../utils/isDevOrLive'
import SignInForm from '../SignIn/SignIn'

import logoUrl, { ReactComponent as Logo } from '../../static/images/logo.svg'

let withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    render() {
      return this.context.authUser ? (
        <Component {...this.props} />
      ) : (
        <div className="auth-form">
          <Logo className="auth-form__logo"/>
          <h1>Welcome, Chief!</h1>
          <SignInForm />
        </div>
      )
    }
  }

  WithAuthorization.contextTypes = {
    authUser: PropTypes.object,
  }

  return isDevOrLive ? Component : WithAuthorization
}

if (isDevOrLive) {
  withAuthorization = null
}

export default withAuthorization
