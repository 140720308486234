import React from 'react'
import SearchUrl, {
  ReactComponent as Search,
} from '../../static/images/icons/search.svg'

import './styles.scss'

let SearchButton = props => {
  return (
    <button role="button" className="b-search-button" aria-label="search">
      <Search className="b-search-button__icon" />
    </button>
  )
}

export default SearchButton
