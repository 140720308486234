import React, { PureComponent, Fragment } from 'react';

import classNames from 'classnames'

import './styles.scss'

import Modal from '../Modal/Modal'
import ModalSubscribe from '../ModalSubscribe/ModalSubscribe';

class MainButton extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			modalShouldBeRender: false,
		};

		this.handleShow = this.handleShow.bind(this);
		this.handleHide = this.handleHide.bind(this);

	}

	handleShow(e) {
		let scrollTopPosition = document.documentElement.scrollTop || document.body.scrollTop

		this.setState({
			scrollTopPosition: scrollTopPosition
		})

		document.querySelector('body').style.top = (- scrollTopPosition) + 'px'

		this.setState({ showModal: true });

		document.querySelector('body').classList.add('body--fixed')
	}

	handleHide() {
		this.setState({ showModal: false });
		document.querySelector('body').classList.remove('body--fixed')

		document.documentElement.scrollTop = document.body.scrollTop = this.state.scrollTopPosition;
	}

	render() {
		const { modal, title, visible } = this.props.data
		const { className } = this.props
		const size = this.props.data.size[0].toLowerCase()
		const color = this.props.data.color[0].toLowerCase()
		const modalEl = this.state.showModal ? (
			<Modal>
				<button className="modal__hide" onClick={this.handleHide}></button>
				<ModalSubscribe data={modal} close={() => this.handleHide()} />
			</Modal>
		) : null;
		return (
			<Fragment>
				<button
					onClick={this.handleShow}
					className={classNames('b-button b-button--secondary-cta', {
						[`b-button--secondary-${size}`]: size,
						[`b-button--secondary-${color}`]: color,
						'b-button--hidden': !visible,
						className: className
					})}>
					{title}
				</button>
				{modalEl}
			</Fragment>
		)
	}
}

export default MainButton;