import React, { Component, PureComponent } from 'react'
import { Link } from 'gatsby'
import './styles.scss'

class BreadCrumbs extends PureComponent {
  getSecondLink() {
    if (this.props.path !== '/') {
      return (
        <li className="b_breadcrumbs__item">
          <a href="#" className="b_breadcrumbs__link">
            {this.props.pages.map(page => {
              const slug = `/${
                this.props.langKey !== 'en' ? `${this.props.langKey}/` : ''
              }${page.slug}`
              if (slug === this.props.path) {
                return `${page.nameInNav}`
              }
            })}
          </a>
        </li>
      )
    } else {
      return
    }
  }

  render() {
    const { pages, langKey } = this.props

    return (
      <ul className="b_breadcrumbs">
        <li className="b_breadcrumbs__item">
          <Link
            to={`/${langKey !== 'en' ? `${langKey}` : ''}`}
            className="b_breadcrumbs__link"
          >
            Home
          </Link>
        </li>
        {this.getSecondLink()}
      </ul>
    )
  }
}

export default BreadCrumbs
