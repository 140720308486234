import React from 'react'

const FacebookHr = props => (
  <svg
    className={props.className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      d="M16,0.055C7.194,0.055,0.055,7.193,0.055,16c0,8.807,7.139,15.945,15.945,15.945c8.807,0,15.945-7.139,15.945-15.945
	C31.945,7.194,24.807,0.055,16,0.055 M16,30.031C8.25,30.031,1.968,23.749,1.968,16S8.25,1.969,16,1.969S30.031,8.251,30.031,16
	S23.75,30.031,16,30.031"
    />
    <g>
      <path
        d="M16.796,23.643v-7.121h2.106l0.279-2.455h-2.386l0.003-1.228c0-0.641,0.066-0.982,1.051-0.982h1.316V9.402H17.06
		c-2.53,0-3.42,1.19-3.42,3.192v1.473h-1.578v2.455h1.578v7.121H16.796L16.796,23.643z"
      />
    </g>
  </svg>
)

export default FacebookHr
