import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { injectIntl, FormattedMessage } from 'react-intl'
import onClickOutside from 'react-onclickoutside'

import './index.scss'

class SelectLanguage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      langs: {
        en: '/',
        fr: '/fr',
        de: '/de',
      },
      isListOpen: false,
      langsNames: {
        en: 'English',
        fr: 'Français',
        de: 'Deutsch',
      },
    }
  }

  triggerList() {
    this.setState({
      isListOpen: !this.state.isListOpen,
    })
  }

  handleClickOutside = evt => {
    if (this.state.isListOpen) {
      this.triggerList()
    }
  }

  render() {
    let { langs, langsNames, isListOpen } = this.state
    let { currentLang, intl } = this.props

    if (this.props.langs && this.props.langs.length > 0) {
      langs = JSON.parse(this.props.langs)
    }

    return (
      <div className="b-language">
        <span className="b-language__title">
          <FormattedMessage id="language" />
        </span>
        <a
          title={intl.messages.selectLanguage}
          className={`b-language__button ${
            isListOpen ? 'b-language__button--active' : ''
          }`}
          onClick={() => this.triggerList()}
        >
          {langsNames[currentLang]}
        </a>
        <ul
          className={`b-language__list ${
            isListOpen ? 'b-language__list--open' : ''
          }`}
        >
          {Object.keys(langs)
            .filter(lang => lang !== 'en-US')
            .map(lang => (
              <li key={langs[lang]} className="b-language__item">
                <Link
                  to={langs[lang]}
                  onClick={() => this.triggerList()}
                  className={`b-language__link ${
                    currentLang == lang ? 'b-language__link--active' : ''
                  }`}
                >
                  {langsNames[lang]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    )
  }
}

export default injectIntl(onClickOutside(SelectLanguage))
