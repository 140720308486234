import React from 'react'
import PropTypes from 'prop-types'
import AuthBar from '../AuthBar/AuthBar'
import isDevOrLive from '../../utils/isDevOrLive'

let withAuthBar = condition => Component => {
  class WithAuthBar extends React.Component {
    render() {
      const { location, authUser, children } = this.props
      const url = location.pathname

      if (url === '/auth') {
        return children()
      }

      return (
        <div>
          {authUser && <AuthBar {...authUser} />}
          <Component {...this.props} />
        </div>
      )
    }
  }

  return isDevOrLive ? Component : WithAuthBar
}

if (isDevOrLive) {
  withAuthBar = null
}

export default withAuthBar
