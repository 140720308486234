import React from 'react'

const LinkedInHr = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M16.055,0.075C7.249,0.075,0.11,7.214,0.11,16.021c0,8.806,7.139,15.945,15.945,15.945S32,24.826,32,16.021	C32,7.214,24.861,0.075,16.055,0.075 M16.055,30.052c-7.749,0-14.031-6.282-14.031-14.031c0-7.75,6.282-14.032,14.031-14.032	c7.75,0,14.032,6.282,14.032,14.032C30.087,23.77,23.805,30.052,16.055,30.052" />
    <rect x="9.039" y="12.907" width="3.004" height="9.492" />
    <path d="M10.598,8.414c-1.027,0-1.7,0.692-1.7,1.601c0,0.891,0.652,1.603,1.661,1.603h0.019c1.047,0,1.7-0.712,1.7-1.603	C12.259,9.106,11.625,8.414,10.598,8.414" />
    <path d="M19.612,12.907c-1.595,0-2.31,0.9-2.708,1.531v-1.313h-3.005c0.04,0.87,0,9.273,0,9.273h3.005V17.22	c0-0.277,0.02-0.555,0.099-0.753c0.218-0.554,0.712-1.127,1.542-1.127c1.087,0,1.521,0.85,1.521,2.098v4.961h3.004V17.08	C23.07,14.233,21.589,12.907,19.612,12.907" />
  </svg>
)

export default LinkedInHr
