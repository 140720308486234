import React, { Component } from 'react';
import CommonContext from "./CommonContext"

class ContextProvider extends Component {
	state = {
		activeDepartmentFilter: "All",
		changeDepartmentFilter: (department) => this.setState({
			activeDepartmentFilter: department
		}),

		activePositionsPage: "Careers",
		changeActivePostionsPage: (page) => this.setState({
			activePositionsPage: page
		}),

		activeHomeSection: '',
		changeActiveSection: (section) => this.setState({
			activeHomeSection: section
		}),
		isMobileNavOpen: false,
		changeIsMobileNavOpen: (isMobileNavOpen) => this.setState({
			isMobileNavOpen: isMobileNavOpen
		})
	}
	render() { 
		return ( 
			<CommonContext.Provider value={{
				state: this.state
			}}> 
				{this.props.children}
			</CommonContext.Provider>
		)
	}
}
 
export default ContextProvider;