import cn from "classnames";
import React from "react";

import './styles.scss';


export const Button = ({
	data: {
		size: propsSize,
		color: propsColor,
		text,
		type = 'button',
		colorNew
	},
	children,
	theme,
	className: propsClassName,
}) => {
	let color, size;

	if (propsColor)
		color = propsColor[0].toLowerCase();

	if (propsSize)
		size = propsSize[0].toLowerCase();

	const buttonClassName = cn('b-button', propsClassName, {
		'b-button--main-cta': !theme,
		[`b-button--${size}`]: size,
		[`b-button--${color}`]: color,
		[`b-button--theme-${theme}`]: theme,
		[`b-button--color-${colorNew}`]: colorNew
	});

	return (
		<button
			type={type}
			className={buttonClassName}
		>
			{text || children}
		</button>
	);
}
