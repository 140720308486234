import React, { PureComponent, Fragment } from 'react';
import Img from 'gatsby-image'

import arrowUrl, {
	ReactComponent as Arrow,
} from '../../../static/images/icons/feat_arr.svg'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../Location/styles.scss'

import Slider from 'react-slick'

const SampleNextArrow = props => {
	const { className, style, onClick } = props
	return (
		<div className={className} onClick={onClick}>
			{' '}
			<Arrow />
		</div>
	)
}

const SamplePrevArrow = props => {
	const { className, style, onClick } = props
	return (
		<div className={className} onClick={onClick}>
			{' '}
			<Arrow />
		</div>
	)
}

const LocationSliderHeader = ({ title, description, tagLine }) => {
	return (
		<div className="b-location-slider-header">
			<div className="b-location-slider-header__tagline">{tagLine}</div>
			<h2 className="b-location-slider-header__title">
				{title}
			</h2>
			<div className="b-location-slider-header__line"></div>
			<div className="b-location-slider-header__description">
				{description}
			</div>
		</div>
	)
}

const detectWebpSupport = () => {
	let format = ''
	if (typeof window !== 'undefined') {
		let ua = navigator.userAgent.match(
			/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
		)
		format = ua[1].toLowerCase() === 'chrome' ? 'webp' : 'jpg'
	}
	return format
}

class HomeSlider extends PureComponent {
	render() {
		const { 
			tagLine,
			title,
			description,
			slides,
		} = this.props

		const chosePadding = () => {
			if (typeof window !== 'undefined' && window.innerWidth < 480) {
				return 10
			} else if (typeof window !== 'undefined' && window.innerWidth < 780) {
				return 50
			} else {
				return 150
			}
		}

		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			swipeToSlide: 1,
			centerPadding: chosePadding(),
			centerMode: true,
			adaptiveHeight: true,
			arrow: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
		}
		return (
			<Fragment >
				<section className="g-container g-container--no-pr g-container--slider">
					<div className="b-location">
						<div className="b-location-slider">
							{slides && slides.length > 0 && (
								<Slider {...settings}>
									{slides.map((slide, key) => {
										return (
											<div key={key} className="b-location-slider__wrapper">
												<div className="b-location-slider__image-wrapper">
													<Img
														className="b-location-slider__image"
														fluid={slide.fluid}
													/>
												</div>
												{key === 0 && title && <LocationSliderHeader title={title} description={description} tagLine={tagLine} />}
											</div>
										)
									})}
								</Slider>
							)}
						</div>
					</div>
				</section>
			</Fragment>
		)
	}
}

export default HomeSlider;