import React, { PureComponent } from 'react';

import './styles.scss'
class TextBanner extends PureComponent {
	render() {
		let { heading, subtitleLong } = this.props
		return (
			<div className="g-container g-container--fw-tablet">
				<div className="b-text-banner">
					{heading &&
						<h2 className="b-text-banner__title t-title-middle t-title-middle--dark" dangerouslySetInnerHTML={{
							__html: heading,
						}} />
					}
					<div className="b-text-banner__line"></div>
					{subtitleLong &&
						<div className="b-text-banner__subtitle t-paragraph t-paragraph--gray" dangerouslySetInnerHTML={{
							__html: subtitleLong.childMarkdownRemark.html,
						}} />
					}
				</div>
			</div>
		)
	}
}

export default TextBanner