import React, { Component } from 'react';

import SelectLanguage from '../SelectLanguage/SelectLanguage';
import CtaButton from '../CtaButton/CtaButton';
import LinkedInHr from '../Icons/LinkedInHr';
import SharekeyHr from '../Icons/SharekeyHr';
import TwitterHr from '../Icons/TwitterHr';
import FacebookHr from '../Icons/FacebookHr';

import logo from '../../static/images/logo_white.svg';
import './index.scss';


const FooterHeader = () => (
	<div className="b-footer__header">
		<h2 className="b-footer__title">
			Become a beta tester. Limited accounts nr.
		</h2>
		<p className="b-footer__lead">
			We are an innovative collaborative platform where you & your organization
			bring People and pieces together to get your work done. In a very simple
			way, pilot your entire business Ecosystem.
		</p>
		<CtaButton text="try the beta" type="cta-wide" />
	</div>
)

const FooterNavigationLink = ({ link }) => (
	<li className="b-footer-nav__item">
		<a
			href={link.url}
			onClick={()=>window.plausible(`Click Link ${link.url}`, {props: {page: window.location.pathname}})}
			className="b-footer-nav__link"
		>
			{link.title}
		</a>
	</li>
)

class Footer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			companyLinks: [
				{
					title: 'About Us',
					url: '#',
				},
				{
					title: 'Shareky Monifesto',
					url: '#',
				},
				{
					title: 'Our Infrastructure',
					url: '#',
				},
				{
					title: 'We are Swiss Company',
					url: '#',
				},
				{
					title: 'Investors',
					url: '#',
				},
				{
					title: 'Media',
					url: '#',
				},
				{
					title: 'Careers',
					url: '/careers/meet-us',
				},
			],
			productLinks: [
				{
					title: 'Features',
					url: '#',
				},
				{
					title: 'Why we are different',
					url: '#',
				},
				{
					title: 'Pricing',
					url: '#',
				},
				{
					title: 'Security',
					url: '#',
				},
				{
					title: 'Privacy',
					url: '#',
				},
				{
					title: 'Made for Liquid Business',
					url: '#',
				},
			],
			solutionLinks: [
				{
					title: 'By Use Case',
					url: '#',
				},
				{
					title: 'By Industry',
					url: '#',
				},
				{
					title: 'Sharekey for Business',
					url: '#',
				},
				{
					title: 'Enterprise solutions',
					url: '#',
				},
			],
			apiLinks: [
				{
					title: 'Developers',
					url: '#',
				},
				{
					title: 'Ressellers',
					url: '#',
				},
				{
					title: 'Integrations',
					url: '#',
				},
				{
					title: 'Blockchain & ICO',
					url: '#',
				},
			],
			supportLinks: [
				{
					title: 'Live chat',
					url: '#',
				},
				{
					title: 'Contact Us',
					url: '#',
				},
				{
					title: 'Support Base & FAQ',
					url: '#',
				},
				{
					title: 'Ressources',
					url: '#',
				},
				{
					title: 'Blog',
					url: '#',
				},
			],
			blockchainLinks: [
				{
					title: 'Sharekey ICO',
					url: '#',
				},
			],
		}
	}
	render() {
		return (
			<footer className="s-footer">
				<section className="g-container g-container--footer-top">
					<FooterHeader />
				</section>
				<section className="g-container g-container--footer-middle">
					<div className="b-footer-nav">
						<div className="g-item g-item--footer g-item--footer-wide">
							<div className="b-footer-nav__info">
								<img src={logo} alt="sharekey" className="b-footer__logo" />
								<span className="b-footer-nav__item b-footer-nav__item--info">
									SHAREKEY AG
								</span>
								<br />
								<a
									className="b-footer-nav__link b-footer-nav__item--info"
									href="https://goo.gl/maps/L6qsQPan4SD2"
									target="blank"
									onClick={()=>window.plausible('Click Google Maps Link', {props: {page: window.location.pathname}})}
								>
									Gotthardstrasse 26<br />
									6300 Zug - Switzerland
								</a>
								<br />
								<a
									className="b-footer-nav__link b-footer-nav__item--info"
									href="tel:+41412332334"
									onClick={()=>window.plausible('Click Call via phone in footer', {props: {page: window.location.pathname}})}
								>
									+41 41 233 23 34
								</a>
							</div>
							<SelectLanguage
								langs={this.props.langs}
								currentLang={this.props.locale}
							/>
						</div>
						<div className="g-item g-item--footer">
							<h3 className="b-footer-nav__header">Our company</h3>
							<ul className="b-footer-nav__list">
								{this.state.companyLinks.map((link, key) => (
									<FooterNavigationLink link={link} key={key} />
								))}
							</ul>
						</div>
						<div className="g-item g-item--footer">
							<h3 className="b-footer-nav__header">Product</h3>
							<ul className="b-footer-nav__list">
								{this.state.productLinks.map((link, key) => (
									<FooterNavigationLink link={link} key={key} />
								))}
							</ul>
						</div>
						<div className="g-item g-item--footer">
							<h3 className="b-footer-nav__header">Solutions</h3>
							<ul className="b-footer-nav__list">
								{this.state.solutionLinks.map((link, key) => (
									<FooterNavigationLink link={link} key={key} />
								))}
							</ul>
						</div>
						<div className="g-item g-item--footer">
							<h3 className="b-footer-nav__header">API & Ecosystem</h3>
							<ul className="b-footer-nav__list">
								{this.state.apiLinks.map((link, key) => (
									<FooterNavigationLink link={link} key={key} />
								))}
							</ul>
						</div>
						<div className="g-item g-item--footer">
							<h3 className="b-footer-nav__header">Support</h3>
							<ul className="b-footer-nav__list">
								{this.state.supportLinks.map((link, key) => (
									<FooterNavigationLink link={link} key={key} />
								))}
							</ul>
						</div>
						<div className="g-item g-item--footer">
							<h3 className="b-footer-nav__header">Blockchain</h3>
							<ul className="b-footer-nav__list">
								{this.state.blockchainLinks.map((link, key) => (
									<FooterNavigationLink link={link} key={key} />
								))}
							</ul>
						</div>
					</div>
				</section>
				<section className="g-container g-container--footer-bottom">
					<div className="b-footer-follow">
						<div className="g-item g-item--footer g-item--footer-wide">
							<div className="b-footer__line" />
						</div>
						<div className="g-item g-item--footer g-item--footer-span3">
							<div className="b-footer-follow__header">
                <span className="b-footer-follow__lead">
                  Made for liquid business
                </span>
								<h2 className="b-footer-follow__title">
									Why we are exactly what you need, but better.
								</h2>
							</div>
						</div>
						<div className="g-item g-item--footer g-item--footer-span2">
							<div className="b-footer-socials">
								<span className="b-footer-socials__title">Follow Us</span>
								<ul className="b-footer-socials__nav">
									<li className="b-footer-socials__item">
										<a
											href="#"
											target="_blank"
											rel="noopener"
											className="b-footer-socials__link"
											title="Sharekey on linkedIn"
											onClick={()=>window.plausible('Click Sharekey on Linkedin', {props: {page: window.location.pathname}})}
										>
											<LinkedInHr className="b-footer-socials__icon" />
										</a>
									</li>
									<li className="b-footer-socials__item">
										<a
											href="#"
											target="_blank"
											rel="noopener"
											className="b-footer-socials__link"
											title="Sharekey on twitter"
											onClick={()=>window.plausible('Sharekey on Twitter', {props: {page: window.location.pathname}})}
										>
											<TwitterHr className="b-footer-socials__icon" />
										</a>
									</li>
									<li className="b-footer-socials__item">
										<a
											href="#"
											target="_blank"
											rel="noopener"
											className="b-footer-socials__link"
											title="Sharekey on facebook"
											onClick={()=>window.plausible('Click Sharekey on Facebook', {props: {page: window.location.pathname}})}
										>
											<FacebookHr className="b-footer-socials__icon" />
										</a>
									</li>
									<li className="b-footer-socials__item">
										<a
											href="#"
											target="_blank"
											rel="noopener"
											className="b-footer-socials__link"
											title="Sharekey"
											onClick={()=>window.plausible('Click Footer Social Links', {props: {page: window.location.pathname}})}
										>
											<SharekeyHr className="b-footer-socials__icon" />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>

				</section>
			</footer>
		)
	}
}

export default Footer
