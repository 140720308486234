import React, { PureComponent } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Observer from 'react-intersection-observer';
import { Link } from 'gatsby';
import Img from 'gatsby-image'

import { LINKS } from '../../../constants/links';
import { Button } from '../../Buttons/Button';
import MainButton from '../../Buttons/MainButton';

import './styles.scss'
import SecurityPaperButton from '../SecurityPaperButton/SecurityPaperButton';

class HomeTabs extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			tabIndex: 0,
			animateTab: false,
			tabMaxHeight: 'auto',
			onOrientationChange: false
		}
		this.video = []
		this.tabs = []
	}

	componentDidMount() {
		this.getMaxHeight()

		if (typeof window !== undefined && 'onorientationchange' in window) {
			window.addEventListener('orientationchange', this.handleOrientationChange)
		}
	}

	componentWillUnmount() {
		if (typeof window !== undefined && 'onorientationchange' in window) {
			window.removeEventListener('orientationchange', this.handleOrientationChange)
		}
	}

	handleOrientationChange = () => {
		this.setState({
			onOrientationChange: !this.state.onOrientationChange
		}, () => this.getMaxHeight())
	}

	getMaxHeight() {
		let tabsHeight = []
		this.tabs.map(( tab, key ) => {
			tabsHeight[key] = tab.offsetHeight
		})
		if (typeof window !== 'undefined' && window.innerWidth > 1021) {
			this.setState({
				tabMaxHeight: Math.max.apply(null, tabsHeight)
			})
		} else {
			this.setState({
				tabMaxHeight: 'auto'
			})
		}
	}

	handleObserveChange(inView, key) {
		if (inView, key === 0) {
			this.video[0] && typeof this.video[0].play() !== 'undefined' && this.video[0].play() && (this.video[0].playbackRate = 1.3)
		}

		if (inView && typeof window !== 'undefined' && window.innerWidth > 1020) {
			this.video[this.state.tabIndex] && typeof this.video[this.state.tabIndex].play() !== 'undefined' && this.video[this.state.tabIndex].play() && (this.video[this.state.tabIndex].playbackRate = 1.3)
		} else if (!inView && typeof window !== 'undefined' && window.innerWidth > 1020) {
			this.video[key] && !this.video[key].paused && this.video[key].currentTime > 0 && this.video[key].pause()
		}

		if (inView && typeof window !== 'undefined' && window.innerWidth < 1021) {
			this.video[key] && typeof this.video[key].play() !== 'undefined' && this.video[key].play() && (this.video[key].playbackRate = 1.3)
		} else if (!inView && typeof window !== 'undefined' && window.innerWidth < 1021) {
			this.video[key] && !this.video[key].paused && this.video[key].currentTime > 0 && this.video[key].pause()
		}

	}

	handleTabClick(key) {
		let videoIndex = key
		this.video.map((video, key) => {
			if (key !== videoIndex) {
				this.video[key] && !this.video[key].paused && this.video[key].currentTime > 0 && this.video[key].pause()
			}
		})

		this.fixSafari()
	}

	fixSafari() {
		let isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
			navigator.userAgent && !navigator.userAgent.match('CriOS');
		let scrollPosition = document.documentElement.scrollTop || window.pageYOffset
		if (isSafari) {
			setTimeout(() => {
				window.scrollTo(0, scrollPosition - 1)
				window.scrollTo(0, scrollPosition)
			}, 300);
		}
	}

	handleOpenBrochure = () => {

	}

	render() {
		const { tabs, buttonSecurityPaper, location } = this.props

		const renderVideo = (tab, key) => {
			return (
				<Observer
					key={key}
					onChange={inView => this.handleObserveChange(inView, key)}
					threshold={[0.5]}
					className=""
				>
					<div className="b-tabs-screen-content__video-holder">
						<div className="b-tabs-screen-content__image-wrapper">
							<Img className="b-tabs-screen-content__image"
								fluid={tab.tabImage.fluid} />
						</div>

						{typeof window !== 'undefined' && window.innerWidth > 1020
							&& <video
								key={key}
								src={typeof window !== 'undefined' && window.devicePixelRatio > 1
									? tab.tabVideoretina.file.url
									: tab.tabVideo.file.url}
								className={'b-tabs-screen-content__video'}
								preload="auto"
								loop={true}
								muted
								playsInline
								ref={node => this.video[key] = node}
							/>
						}
						{typeof window !== 'undefined' && window.innerWidth <= 1020 &&
							<video
								key={key}
								src={typeof window !== 'undefined' && window.devicePixelRatio > 1
									? tab.tabVideo.file.url
									: tab.tabVideomobile.file.url}
								className={'b-tabs-screen-content__video b-tabs-screen-content__video--mobile'}
								preload="auto"
								loop={true}
								muted
								playsInline
								ref={node => this.video[key] = node}
							/>}
					</div>
				</Observer>
				)
			}

			return (
			<div
				className="b-tabs-screen__tabs"
				style={{ minHeight: this.state.tabMaxHeight}}
			>
				<Tabs
					forceRenderTabPanel={true}
					selectedIndex={this.state.tabIndex}
					onSelect={tabIndex => this.setState({ tabIndex })}
				>
					<TabList ref={item => this.tabHeader = item}>
						{tabs.map((tab, key) => {
							return (
								<Tab key={tab.id} onClick={() => this.handleTabClick(key)}>
									<span className="react-tabs__tab-inner">{tab.tabTitle}</span>
								</Tab>
							)
						})}
					</TabList>
					<div className="react-tabs__panels-wrapper">
						{tabs.map((tab, key) => {
							return (
								<TabPanel key={key} >
									<div className="b-tabs-screen-content">
										<div className="b-tabs-screen-content__left"
											style={{ minHeight: this.state.tabMaxHeight }}
											ref={(node) => this.tabs[key] = node}>
											<div className="b-tabs-screen-content__info t-text-block t-text-block--title-mb-18" dangerouslySetInnerHTML={{
												__html: tab.tabDescription.childMarkdownRemark.html,
											}}>
											</div>
											<MainButton data={tab.ctaButton}/>
										</div>
										<div className="b-tabs-screen-content__right"
											style={{ height: this.state.tabMaxHeight }}>
											{tab.tabVideo
												? renderVideo(tab, key)
												: <div className='b-tabs-screen-content__image-wrapper b-tabs-screen-content__image-big'>
														<Img className='b-tabs-screen-content__image b-tabs-screen-content__image-big'
															fluid={tab.tabImage.fluid} />
													</div>
											}
										</div>
									</div>
								</TabPanel>
							)
						})}
					</div>
				</Tabs>
				<div className="b-tabs-screen__brochure">
					<a target='_blank' href={LINKS.BROCHURE} onClick={()=>window.plausible('Click Open Brochure', {props: {page: window.location.pathname}})}>
						<Button data={{ color: ['Red'] }}>
							Brochure
						</Button>
					</a>
					{/* <Link to={LINKS.SUPPORT}>
						<Button className='b-tabs-screen__brochure-security-paper' data={buttonSecurityPaper} />
					</Link> */}
					<SecurityPaperButton
						className='b-tabs-screen__brochure-security-paper'
						text='SECURITY PAPER'
						location={location}
					/>
					<a target='_blank' href={LINKS.SOURCE_CODE} onClick={()=>window.plausible('Click Open Source Code', {props: {page: window.location.pathname}})}>
						<Button data={{ colorNew: 'gray' }}>
							SOURCE CODE
						</Button>
					</a>
				</div>
			</div>
		)
	}
}

export default HomeTabs;