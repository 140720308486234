import React, { PureComponent, Fragment } from 'react';
import { Link } from 'gatsby'
import classNames from 'classnames'
import axios from 'axios'
import './styles.scss'

import galaUrl, {
	ReactComponent as GalaIcon,
} from '../../static/images/icons/gala.svg'

class ModalSubscribe extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isAgree: false,
			email: '',
			inputHasСharacter: false,
			isEmail: true,
			inputLeftPadding: 0,
			isAgreeError: false,
			formSended: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	handleChange(event) {
		this.setState({
			email: event.target.value,
			isEmail: true
		});
		if (event.target.value.length > 0 ) {
			this.setState({
				inputHasСharacter: true,
				inputLeftPadding: 0
			})
		} else {
			this.setState({
				inputHasСharacter: false,
				inputLeftPadding: (this.input.offsetWidth - this.label.offsetWidth) / 2,
			})
		}
	}
	toggleAgreement() {
		this.setState({
			isAgree: !this.state.isAgree,
			isAgreeError: false
		})
	}
	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation()
		const emailPattern = /\S+@\S+\.\S+/;

		if (emailPattern.test(this.state.email)) {
		} else {
			this.setState({isEmail: false})
		}
		this.setState({
			isAgreeError: !this.state.isAgree
		});

		if (this.state.isAgree && emailPattern.test(this.state.email)) {
			axios.post('https://formbucket.com/f/buk_br8Eo7DkQXgFxsVWBjU4UKim', {
				email: this.state.email,
			})
			.then(res => {
				if (res.status !== 200)
					return;

				this.setState({
					formSended: true
				});

				setTimeout(() => {
					this.props.close()
				}, 3000);
			})
			.catch(err => console.error(err));
		}
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				inputLeftPadding: (this.input.offsetWidth - this.label.offsetWidth) / 2,
			})
		}, 300);
	}

	render() {
		const inputLeftPadding = {
			paddingLeft: `${this.state.inputLeftPadding}px`
		}
		const { isAgree, isAgreeError} = this.state
		const {
			name,
			title,
			description,
			tagLine,
			emailPlaceholder,
			agreementText,
			linkToPage,
			submitButtonText,
			emailErrorMessage,
			tanksTitle,
			thanksDescription,
		} = this.props.data
		return (
			<div className="b-modal-subscribe">
				{this.state.formSended
					? <div>
						<h2 className="b-modal-subscribe__thanks-title">{tanksTitle}</h2>
						<span className="b-modal-subscribe__thanks-description">{thanksDescription}</span>
					</div>
					: <Fragment>
						<div className="b-modal-subscribe__tagline">{tagLine}</div>
						<h2 className="b-modal-subscribe__title">{title}</h2>
						<div className="b-modal-subscribe__description">{description}</div>
						<form action="/" method="post" className="b-modal-subscribe__form">
							<div className="b-modal-subscribe__mail-input-wrapper">
								<div className="b-modal-subscribe__mail-error">
									{!this.state.isEmail ? emailErrorMessage : ""}
								</div>
								<div className="b-modal-subscribe__mail-input-wrapper">
									<input
										type="text"
										ref={(input) => { this.input = input }}
										style={inputLeftPadding}
										id="mail-input"
										autoFocus
										className={`b-modal-subscribe__mail-input ${this.state.inputHasСharacter ? 'b-modal-subscribe__mail-input--filled' : ""}`}
										value={this.state.value}
										onChange={this.handleChange}
									/>
									<label ref={(label) => { this.label = label }} htmlFor="mail-input"
										className={`b-modal-subscribe__mail-input-label ${this.state.inputHasСharacter
											? 'b-modal-subscribe__mail-input-label--hidden' : ""}`}>
										{emailPlaceholder}
									</label>
								</div>
							</div>
							<legend className="b-modal-subscribe__legend ">
								<input id="b-modal-subscribe__checkbox" className="b-modal-subscribe__checkbox" type="checkbox" />
								<label htmlFor="b-modal-subscribe__checkbox"
									className="b-modal-subscribe__checkbox-label"
									onClick={() => this.toggleAgreement()}>
									<span className={classNames('b-modal-subscribe__checkbox-holder',
										{ 'b-modal-subscribe__checkbox-holder--error': isAgreeError },
										{ 'b-modal-subscribe__checkbox-holder--active': isAgree }
									)}>
										<GalaIcon className={classNames('b-modal-subscribe__galochka',
											{ 'b-modal-subscribe__galochka--active': isAgree },

										)} />
									</span>
									{agreementText}&nbsp;&nbsp;
								</label>
								{/* <Link to={`/${linkToPage.slug}`}
									target="_blank"
									className="b-modal-subscribe__policy-link"
								>Read our privacy policy.</Link> */}
							</legend>
							<button
								className="b-button b-button--main-cta"
								onClick={this.handleSubmit}
								type="submit"
								target="_blank"
							>
								{submitButtonText}</button>
						</form>
					</Fragment>
				}
			</div>
		)
	}
}

export default ModalSubscribe;
