import React, { PureComponent, Fragment } from "react";

import "./styles.scss";

import Modal from "../Modal/Modal";
import ModalSupport from "../ModalSupport";

class SupportButton extends PureComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			showModal: false,
			modalShouldBeRender: false
		};
		
		this.handleShow = this.handleShow.bind(this);
		this.handleHide = this.handleHide.bind(this);
		
	}
	
	handleShow(e) {
		e.preventDefault();
		this.setState({ showModal: true });
		document.querySelector("body").classList.add("body--fixed");
	}
	
	handleHide() {
		this.setState({ showModal: false });
		document.querySelector("body").classList.remove("body--fixed");
	}
	
	render() {
		const props = this.props;
		
		return (
			<Fragment>
				<a href='#' onClick={ this.handleShow } className='b-navigation__link'>
					{ props.text }
				</a>
				{
					this.state.showModal ? (
						<Modal color={ "white" }>
							<button className="modal__hide" onClick={ this.handleHide }/>
							<ModalSupport close={ this.handleHide }/>
						</Modal>
					) : null
				}
			</Fragment>
		);
	}
}

export default SupportButton;
