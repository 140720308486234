import React, { PureComponent, Fragment } from 'react';
import { Link } from 'gatsby'
import classNames from 'classnames'

import './styles.scss'

class MainButtonLink extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {}
	}

	render() {
		const { className } = this.props
		const {  title, ctaLink, visible } = this.props.data
		const size = this.props.data.size[0].toLowerCase()
		const color = this.props.data.color[0].toLowerCase()
		return (
		
			<Link
				to={`/${ctaLink.slug}`}
				className={classNames('b-button b-button--secondary-cta', {
					[`b-button--secondary-${size}`]: size,
					[`b-button--secondary-${color}`]: color,
					'b-button--hidden': !visible,
					className: className
				})}>
				{title}
			</Link>
		)
	}
}

export default MainButtonLink;