import React, { Component } from 'react'

import MainButton from '../Buttons/MainButton';

import { ReactComponent as Logo } from '../../static/images/icons/logo_white.svg';
import { ReactComponent as LogoCenter } from '../../static/images/icons/logo__middle.svg'

import './index.scss'

const FooterNavigationLink = ({ link }) => (
	<li className="b-footer-nav__item">
		<a href={link.url} className="b-footer-nav__link">
			{link.title}
		</a>
	</li>
)

class FooterLight extends Component {
	render() {
		const {
			title,
			description,
			ctaButton,
			rightsReservedLink
		} = this.props.data;

		const footerHeader = () => {
			return (
				<div className="b-footer__header">
					<h2 className="b-footer__title" dangerouslySetInnerHTML={{
						__html: title,
					}}/>

					<p className="b-footer__lead" dangerouslySetInnerHTML={{
						__html: description,
					}} />
					<MainButton data={ctaButton} />
				</div>
			)
		}
		const footerHeaderPreAlpha = () => {
			return (
				<div className="b-footer__header">
					<h2 className="b-footer__title" dangerouslySetInnerHTML={{
						__html: this.props.dataPreAlpha.title,
					}}/>
					<p className="b-footer__lead">
						{this.props.dataPreAlpha.description}
					</p>
					<a
						href="/"
						className="b-button b-button--main-cta b-button--wide b-button--red"
						onClick={()=>window.plausible('Click TRY THE BETA', {props: {page: window.location.pathname}})}
					>
						TRY THE BETA
					</a>
				</div>
			)
		}
		return (
			<footer className="s-footer">
				<section className="g-container g-container--footer-middle">
					<div className="b-footer-nav">
						<div className="g-item g-item--footer g-item--footer-wide">
							<div className="b-footer__light">
								<div className="b-footer-nav__info b-footer-nav__info--light">
									<Logo className="b-footer__logo" />
									<LogoCenter className="b-footer__logo b-footer__logo--mobile"/>
									<span className="b-footer-nav__item b-footer-nav__item--info">
										SHAREKEY Swiss AG
									</span>
									<br />
									<a
										className="b-footer-nav__link b-footer-nav__item--info"
										href="https://goo.gl/maps/L6qsQPan4SD2"
										target="blank"
										onClick={()=>window.plausible('Click Google Maps Link', {props: {page: window.location.pathname}})}
									>
										Gotthardstrasse 26<br />
										6300 Zug <b>&#8231;</b> Switzerland
									</a>
									<br />
									<a
										className="b-footer-nav__link b-footer-nav__item--info"
										href="tel:+41762267600"
										onClick={()=>window.plausible('Click Call Via Phone Number', {props: {page: window.location.pathname}})}
									>
										+41 76 226 76 00
									</a>
									<br/>
									<a
										className="b-footer-nav__link b-footer-nav__item--info"
										href="mailto:info@sharekey.com"
										onClick={()=>window.plausible('Click Send Email to info@', {props: {page: window.location.pathname}})}
									>
										info@sharekey.com
									</a>
								</div>
								<div className="g-item g-item--footer">
									<div className="b-footer-follow__header">
										<span className="b-footer-follow__lead">
											Made for liquid business
										</span>
										<h2 className="b-footer-follow__title">
											We secure your business privacy <br/>
											<span className='b-footer-follow__sub-title'>Anywhere, Anytime, Any device</span>
										</h2>
									</div>
								</div>
								<div className="b-footer__line b-footer__line--light" />
							</div>
						</div>
					</div>
				</section>
			</footer>
		)
	}
}

export default FooterLight
