import React from 'react'
import PropTypes from 'prop-types'
import isDevOrLive from '../../utils/isDevOrLive'
import { firebase } from '../../firebase'

let withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        authUser: null,
      }
    }

    getChildContext() {
      return {
        authUser: this.state.authUser,
      }
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged(authUser => {
        authUser
          ? this.setState(() => ({ authUser }))
          : this.setState(() => ({ authUser: null }))
      })
    }

    render() {
      return <Component {...this.props} authUser={this.state.authUser} />
    }
  }

  WithAuthentication.childContextTypes = {
    authUser: PropTypes.object,
  }

  return isDevOrLive ? Component : WithAuthentication
}

if (isDevOrLive) {
  withAuthentication = null
}

export default withAuthentication
