import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

class Modal extends PureComponent {
	constructor(props) {
		super(props);
		
		if (typeof document !== `undefined`) this.el = document.createElement('div');
	}
	
	componentDidMount() {
		if (this.el) {
			document.getElementById('modal').appendChild(this.el);
			document.getElementById('modal').classList.add(`modal--full`, `modal--${ this.props.color }`);
		}
	}
	
	componentWillUnmount() {
		if (this.el) {
			document.getElementById('modal').classList.remove('modal--full', `modal--${ this.props.color }`);
			document.getElementById('modal').removeChild(this.el);
		}
	}
	
	render() {
		return this.el ? ReactDOM.createPortal(
			this.props.children,
			this.el
		) : null;
	}
}

export default Modal;
