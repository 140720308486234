import React, { PureComponent } from 'react'
import { Link } from 'gatsby'

import Burger from '../Burger/Burger'
import BreadCrumb from '../BreadCrumb/BreadCrumb'
import Navigation from '../Navigation/Navigation'
import CtaButton from '../CtaButton/CtaButton'
import SearchButton from '../SearchButton/SearchButton'
import SupportButton from '../SupportButton';

import './styles.scss'
import logoSmall from '../../static/images/logo_small.svg'

const Logo = () => (
  <img className="b-header__logo" src={logoSmall} alt="sharekey" />
)

class Header extends PureComponent {
  render() {
    return (
      <header
        className={`s-header ${this.props.isFixed ? 's-header--fixed' : ''}`}
      >

        <div className="g-container g-container--header">
          <div className="g-item--header-left">
            <Burger />
            {this.props.isFixed ? (
              <Logo />
            ) : (
              <BreadCrumb
                path={this.props.path}
                pages={this.props.nav.pages}
                langKey={this.props.langKey}
              />
            )}
          </div>
          <div className="g-item--header-right">
            {/*<SupportButton text="support" />*/}
            <Navigation
              pages={this.props.nav.pages}
              langKey={this.props.langKey}
            />
            <SearchButton />

            {/*<CtaButton text="try the beta" />*/}
          </div>
        </div>
      </header>
    )
  }
}

export default Header
