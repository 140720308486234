import React, { PureComponent } from 'react';

import './styles.scss'

import LockIcon from '../../../static/images/lock.png';
import DevicesIcon from '../../../static/images/devices.png';
import SwissIcon from '../../../static/images/swiss.png';

class GreenScreenCards extends PureComponent {
	selctedIcon = icon => {
		if (icon === "lock") {
			return <img
				src={LockIcon}
				className="b-green-screen-cards__card-icon b-green-screen-cards__card-icon--lock"
			/>;
		} else if (icon === "swiss")
			return <img
				src={SwissIcon}
				className="b-green-screen-cards__card-icon b-green-screen-cards__card-icon--swiss"
			/>;
		else if (icon === "devices") {
			return <img
				src={DevicesIcon}
				className="b-green-screen-cards__card-icon b-green-screen-cards__card-icon--devices"
			/>
		}
	}

	render() {
		const { cards } = this.props;

		return (
			<div className="b-green-screen-cards">
				{cards.map((card, key) => {
					return (
						<div key={key} className="b-green-screen-cards__holder">
							<div className="b-green-screen-cards__card">
								{this.selctedIcon(card.icon.iconName)}
								<h3 className="b-green-screen-cards__card-title" dangerouslySetInnerHTML={{
									__html: card.title,
								}}/>
								<div className="b-green-screen-cards__card-line"></div>
								<div
									className="b-green-screen-cards__card-description t-paragraph"
									dangerouslySetInnerHTML={{__html: card.childContentfulLandingPageComponentsGreenCardsDescriptionTextNode.childMarkdownRemark.html}}
								>
								</div>
								{/* <p className="b-green-screen-cards__card-description b-green-screen-cards__card-description--mobile  t-paragraph t-paragraph--white">{card.hiddenDescription}</p> */}
							</div>
							{/* <div className="b-green-screen-cards__card-hover">
								{this.selctedIcon(card.icon.iconName)}
								<h3 className="b-green-screen-cards__card-hover-title" dangerouslySetInnerHTML={{
									__html: card.title,
								}} />
								<div className="b-green-screen-cards__card-hover-line"></div>
								<p className="b-green-screen-cards__card-hover-description">{card.description}</p>
								<p className="b-green-screen-cards__card-hover-description">{card.hiddenDescription}</p>
							</div> */}
						</div>
					)
				})}
			</div>
		);
	}
}

export default GreenScreenCards;