import React from 'react'
import {
	getCurrentLangKey,
	getLangs,
	getUrlForLang,
} from 'ptz-i18n'
import { addLocaleData, IntlProvider } from 'react-intl'
import 'intl'

import Header from '../Header/Header'
import HeaderLight from '../HeaderLight/HeaderLight'
import Footer from '../Footer/Footer'
import FooterLight from '../FooterLight/FooterLight'
import SubFooter from '../SubFooter/SubFooter'
import ScrollTop from '../ScrollTop/ScrollTop'
import withAuthorization from '../Session/withAuthorization'
import withAuthentication from '../Session/withAuthentication'
import withAuthBar from '../Session/withAuthBar'
import ContextProvider from '../ContextProvider/ContextProvider'

import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import de from 'react-intl/locale-data/de'

addLocaleData([...en, ...fr, ...de])

import messagesEn from '../../data/translations/en.json'
import messagesFr from '../../data/translations/fr.json'
import messagesDe from '../../data/translations/de.json'
import isDevOrLive from '../../utils/isDevOrLive'

import '../../layouts/index.scss'
import '../../layouts/grids.scss'
import '../../layouts/utils.scss'
import '../../layouts/typography.scss'
import '../../static/images/chain.png'

const messages = {
	en: messagesEn,
	fr: messagesFr,
	de: messagesDe,
}

class TemplateWrapper extends React.Component {
	state = {
		isHeaderFixed: false,
		showScrollTop: false,
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}
	handleScroll = e => {
		let scrollTop = document.documentElement.scrollTop || window.pageYOffset
		if (scrollTop > 33) {
			this.setState({
				isHeaderFixed: true,
			})
		} else {
			this.setState({
				isHeaderFixed: false,
			})
		}
		if (window.innerHeight && scrollTop > window.innerHeight) {
			this.setState({
				showScrollTop: true,
			})
		} else {
			this.setState({
				showScrollTop: false,
			})
		}
	}

	render() {
		const { children, data, location, i18nMessages, pageContext,} = this.props

		const url = location.pathname;
		const { langs, defaultLangKey } = data.site.siteMetadata.languages
		const langKey = getCurrentLangKey(langs, defaultLangKey, url)
		const homeLink = `/${langKey}`
		const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
		const langSlugs = pageContext.slugs

		return (
			<ContextProvider>
				<IntlProvider locale={langKey} messages={messages[langKey]}>
					<div
						id="top"
						className={`app ${
							this.state.isHeaderFixed ? 'app--fixed-header' : ''
						} ${this.props.pathContext.slug === "/404/" ? 'app--404' : ''}`}
					>
						{data && data.globalConfig && data.globalConfig.header[0] === "Full" &&
							<Header
								isFixed={this.state.isHeaderFixed}
								nav={data.mainNav}
								langKey={pageContext.langKey}
								path={url}
								homeLink={homeLink}
							/>
						}
						{(this.props.pathContext.slug === "/404/"
							|| (data && data.globalConfig && data.globalConfig.header[0] !== "Full")
							|| url !== '/download') ?
							<HeaderLight
								isFixed={this.state.isHeaderFixed || this.props.pathContext.slug === "/404/"}
								langKey={this.props.pathContext.langKey}
								nav={data.mainNavLight}
								homeLink={homeLink}
								data={data}
								showCarrersNav={this.props.pathContext.slug === "/404/"}
								url={url}
							/> : null
						}
						<main className="main">{children}</main>
						{data && data.globalConfig && data.globalConfig.footer[0] === "Full" &&
							<Footer langs={langSlugs} locale={langKey} />
						}
						{data && data.globalConfig && data.globalConfig.footer[0] !== "Full" && data.mainFooterLight && !url.includes('info') &&
							<FooterLight
								langs={langSlugs}
								locale={langKey}
								data={data.mainFooterLight}
								dataPreAlpha={data.mainFooterLightPreAlpha}
							/>
						}
						<SubFooter rightsReservedLink={data && data.mainFooterLight && data.mainFooterLight.rightsReservedLink} locale={langKey} />
						<ScrollTop show={this.state.showScrollTop} />
					</div>
				</IntlProvider>
			</ContextProvider>
		)
	}
}

const authCondition = authUser => !!authUser;

let Layout = TemplateWrapper

if (!isDevOrLive) {
	Layout = withAuthentication(
		withAuthorization(authCondition)(withAuthBar()(TemplateWrapper))
	)
}

export default Layout
