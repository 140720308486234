import React, { PureComponent, Fragment } from "react";
import { Link } from "gatsby";
import axios from "axios";
import TextareaAutosize from 'react-autosize-textarea';
import classNames from "classnames";

import "./styles.scss";

class ModalSupport extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			firstName: "",
			lastName: "",
			companyName: '',
			email: "",
			subject: this.props.subject || '',
			message: "",
			emailFormatHasError: false,
			emailEmpty: true,
			firstNameEmpty: true,
			lastNameEmpty: true,
			messageEmpty: true,
			subjectEmpty: true,
			formSended: false,
			emptyFields: [],
			prevPage: '/',
		};
	}

	// componentDidMount() {
	// 	const { subject: subjectProps } = this.props;

	// 	subjectProps && this.setState((prevState, props) => ({ subject: props.subject }));
	// }

	handleInputChange = ({ target: { name, value } }) => {
		this.setState({
			[name]: value,
			[`${name}FormatHasError`]: false,
			[`${name}Empty`]: !value.length
		});
	}

	validateEmailInput = () => {
		const emailPattern = /\S+@\S+\.\S+/;
		if (this.state.email.length > 0 && !emailPattern.test(this.state.email)) {
			this.setState({
				emailFormatHasError: true
			});
			return false;
		} else if (this.state.email.length === 0) {
			this.setState({
				emailEmpty: true
			});
			return false;
		}
		return true;
	}

	validateFirstNameInput = () => {
		if (this.state.firstName.length === 0) {
			this.setState({
				firstNameEmpty: true
			});
			return false;
		}
		return true;
	}

	validateLastNameInput = () => {
		if (this.state.lastName.length === 0) {
			this.setState({
				lastNameEmpty: true
			});
			return false;
		}
		return true;
	}

	validateSubjectInput = () => {
		if (this.state.subject.length === 0) {
			this.setState({
				subject: true
			});
			return false;
		}
		return true;
	}

	validateMessageInput = () => {
		if (this.state.message.length === 0) {
			this.setState({
				subject: true
			});
			return false;
		}
		return true;
	}

	checkEmptyFields = () => {
		const emptyFields = Object.keys(this.state).filter((stateProp) => {
			if (this.state[stateProp] === "") {
				return stateProp;
			}
		});
		this.setState({
			emptyFields
		});
		return emptyFields.includes("email") && emptyFields.includes("message");
	}

	handleSubmit = e => {
		const {
			email,
			firstName,
			lastName,
			subject,
			message,
			companyName
		} = this.state;
		e.preventDefault();
		e.stopPropagation();

		if (this.checkEmptyFields()) return;

		this.validateEmailInput();
		this.validateFirstNameInput();
		this.validateLastNameInput();
		this.validateSubjectInput();
		this.validateMessageInput();

		if (this.validateEmailInput() && this.validateMessageInput()) {
			this.setState({
				formSended: true
			});

			let link = "https://api.formbucket.com/f/buk_7wG4hPGGlV9FtkiAgZbf4hnt"

			const data = {
				email,
				firstName,
				lastName,
				subject,
				message,
			};

			if (companyName) {
				data.companyName = companyName;
				link = 'https://formbucket.com/f/buk_br8Eo7DkQXgFxsVWBjU4UKim';
			}


			axios.post(link, data)
				.then(() => {
					setTimeout(() => {
						window.location.href = '/';
					}, 3000);
				})
				.catch(function(error) {
					console.log(error);
				});
		}
	}

	render() {
		const {
			firstName,
			lastName,
			email,
			subject,
			message,
			emailFormatHasError,
			emailEmpty,
			formSended,
			messageEmpty,
			emptyFields
		} = this.state;
		const {
			location,
			subject: subjectProps,
			handleClose
		} = this.props;

		const urlToHideModal = () => {
			if (handleClose)
			return <div className="modal__hide" onClick={handleClose}></div>;

			return (
				<Link
					to={location.state && location.state.prevPath ? location.state.prevPath : '/'}
					className="modal__hide"
				/>
			);
		}

		return (
			<div className="b-modal-support">
				{formSended
					? <div>
						{urlToHideModal()}
						<h2 className="b-modal-support__thanks-title">Thank you</h2>
						<span className="b-modal-subscribe__thanks-description">Your message has been sent successfully to SHAREKEY Support</span>
					</div>
					: <Fragment>
						<div className="b-modal-apply__title">{subjectProps ? subjectProps : 'Write us'}</div>
						{urlToHideModal()}
						<form className="b-modal-apply__form" action="submit">
							<div className="b-modal-apply__form-col b-modal-apply__form-col--left">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<input
										onChange={ e => this.handleInputChange(e) }
										type="text"
										className={
											classNames("b-modal__input-text b-modal-apply__form-input")
										}
										name="firstName"
										id="firstName"
									/>
									<label
										className={ classNames("b-modal__label b-modal-apply__form-label",
											{ "b-modal__label--active": firstName.length > 0 })
										}
										htmlFor="firstName">
										First Name
									</label>
								</div>
							</div>
							<div className="b-modal-apply__form-col b-modal-apply__form-col--right">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<input
										onChange={ e => this.handleInputChange(e) }
										className={
											classNames("b-modal__input-text b-modal-apply__form-input")
										}
										type="text"
										name="lastName"
										id="lastName"
									/>
									<label
										className={ classNames("b-modal__label b-modal-apply__form-label ",
											{ "b-modal__label--active": lastName.length > 0 })
										}
										htmlFor="lastName">
										Last Name
									</label>
								</div>
							</div>
							{subjectProps
								&& <div className="b-modal-apply__form-col b-modal-support__form-col--full-width">
									<div className="b-modal__input-group b-modal-apply__form-input-group">
										<input
											onChange={this.handleInputChange}
											className={classNames("b-modal__input-text b-modal-apply__form-input")}
											type="text"
											name="companyName"
											id="companyName"
										/>
										<label
											className={classNames("b-modal__label b-modal-apply__form-label ",
												{ "b-modal__label--active": lastName.length > 0 })
											}
											htmlFor="companyName">
											Company name
										</label>
									</div>
								</div>
							}
							<div className="b-modal-support__form-col b-modal-support__form-col--full-width">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{ emailEmpty && emptyFields.includes("email") ? "Invalid E-mail" : "" }
										{ emailFormatHasError ? "Wrong e-mail format" : "" }
									</span>
									<input
										onChange={ e => this.handleInputChange(e) }
										className={
											classNames("b-modal__input-text b-modal-apply__form-input",
												{ "b-modal__input-text--error": emailEmpty && emptyFields.includes("email") || emailFormatHasError })
										}
										type="email"
										name="email"
										autoComplete='email'
										id="email"
										required/>
									<label
										className={ classNames("b-modal__label b-modal-apply__form-label",
											{ "b-modal__label--active": email.length > 0 })
										}
										htmlFor="email">
										Email
									</label>
								</div>
							</div>
							<div className="b-modal-support__form-col b-modal-support__form-col--full-width">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<input
										value={subject}
										onChange={ e => this.handleInputChange(e) }
										className={
											classNames("b-modal__input-text b-modal-apply__form-input")
										}
										type="text"
										name="subject"
										id="subject"
									/>
									<label
										className={ classNames("b-modal__label b-modal-apply__form-label",
											{ "b-modal__label--active": subject.length > 0 })
										}
										htmlFor="subject">
										Subject
									</label>
								</div>
							</div>
							<div className="b-modal-support__form-col b-modal-support__form-col--full-width">
								<div className="b-modal__input-group b-modal-apply__form-input-group">
									<span className="b-modal__input-error">
										{ messageEmpty && emptyFields.includes("message") ? "Invalid Message" : "" }
									</span>
									<TextareaAutosize
										onChange={ e => this.handleInputChange(e) }
										className={
											classNames("b-modal__input-text b-modal-apply__form-input",
												{ "b-modal__input-text--error": messageEmpty && emptyFields.includes("message") })
										}
										name="message"
										autoComplete='message'
										id="message"
										required/>
									<label
										className={ classNames("b-modal__label b-modal-apply__form-label",
											{ "b-modal__label--active": message.length > 0 })
										}
										htmlFor="message">
										Message
									</label>
								</div>
							</div>
							<div className="b-modal-support__form-col b-modal-support__form-col--full-width">
								<button
									className="b-button b-button--main-cta b-button--regular b-button--red"
									onClick={ e => this.handleSubmit(e) }
									type="submit"
								>
									Send
								</button>
							</div>
						</form>
					</Fragment>
				}
			</div>
		);
	}
}

export default ModalSupport;
