import React from 'react';

import EarthImage from '../../../static/images/earth.png';
import InsideOutsideImage from '../../../static/images/inside-outside.png';

import './styles.scss'

const DoubleScreen = ({ leftText, rightText }) => (
	<section className="g-container g-container--double-pad g-container--fw-tablet g-container--p40-tablet">
		<div className="b-double-screen">
			<div className="b-double-screen__left">
				<div className='b-double-screen__image-container'>
					<img className='b-double-screen__image b-double-screen__image--recycle' src={InsideOutsideImage}></img>
				</div>
				<div className="b-double-screen__info t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
					__html: leftText.childMarkdownRemark.html,
				}} />
			</div>
			<div className="b-double-screen__right">
				<div className='b-double-screen__image-container'>
					<img className='b-double-screen__image b-double-screen__image--earth' src={EarthImage}></img>
				</div>
				<div className="b-double-screen__info t-text-block t-text-block--title-m-20" dangerouslySetInnerHTML={{
					__html: rightText.childMarkdownRemark.html,
				}} />
			</div>
		</div>
	</section>
);

export default DoubleScreen;
