import React, { PureComponent } from 'react';

import { LINKS } from '../../constants/links';

import Swiss from '../../static/images/icons/_swiss.svg';
import GDPR from '../../static/images/icons/_gdpr.svg';
import Backdor from '../../static/images/icons/_nobckdr.svg';

import { ReactComponent as LabsIcon } from '../../static/images/icons/crypto_labs.svg';
import { ReactComponent as CryptoValley } from '../../static/images/icons/_crypto_valley.svg';
import { ReactComponent as FranceDigitale } from '../../static/images/icons/fd-logo.svg';
import { ReactComponent as HightTechPark } from '../../static/images/icons/logo-htp.svg';

import './styles.scss';

const SubFooter = ({ rightsReservedLink }) => (
	<section className="s-subfooter">
		<div className="g-container g-container--subfooter">
			<div className="b-subfooter">
				<div className="b-subfooter__links">
					<a
						href={rightsReservedLink}
						target="_blank"
						className="b-subfooter__copyright-link"
					>
						<span className="b-subfooter__copyright">
							© {new Date().getFullYear()} SHAREKEY Swiss AG.
							All Rights Reserved.
						</span>
					</a>
					<a
						href={LINKS.PRIVACY_POLICY}
						target='_blank'
						className="b-subfooter__copyright-link"
						onClick={()=>window.plausible('Click Open Privacy Policy', {props: {page: window.location.pathname}})}
					>
						<span className="b-subfooter__copyright">
							Privacy Policy
						</span>
					</a>
					<a
						href={LINKS.TERMS_OF_SERVICE}
						target='_blank'
						className="b-subfooter__copyright-link"
						onClick={()=>window.plausible('Click Open ToS from Subfooter', {props: {page: window.location.pathname}})}
					>
						<span className="b-subfooter__copyright">
							Terms of Service
						</span>
					</a>
				</div>
				<div className="b-subfooter-labels">
					<div className="b-subfooter-labels__container">
						<a className="b-subfooter-labels__link" title="Swiss privacy">
							<img
								className="b-subfooter__icon"
								src={Swiss}
								alt="Swiss privacy"
							/>
						</a>
						<a className="b-subfooter-labels__link" title="GDPR ready">
							<img
								className="b-subfooter__icon"
								src={GDPR}
								alt="GDPR ready"
							/>
						</a>
						<a className="b-subfooter-labels__link" title="No back door">
							<img
								className="b-subfooter__icon"
								src={Backdor}
								alt="No back door"
							/>
						</a>
					</div>
					<div className="b-subfooter-labels__container b-subfooter-labels__container--brands">
						<a
							href="https://cryptovalley.swiss"
							target="_blank"
							rel="noopener"
							className="b-subfooter-labels__link"
							title="Crypto Valley"
						>
							<CryptoValley className="b-subfooter-labels__icon-valley" />
						</a>
						<a href={LINKS.CVVC}
							target="_blank"
							rel="noopener"
							className="b-subfooter-labels__link"
							title="Crypto Valley Labs">
							<LabsIcon className="b-subfooter-labels__icon-labs"/>
						</a>
						<a
							href="https://francedigitale.org/"
							target="_blank"
							rel="noopener"
							className="b-subfooter-labels__link"
							title="France digitale"
						>
							<FranceDigitale className='b-subfooter-labels__icon-fd' />
						</a>
						<a
							href={LINKS.HTP}
							target="_blank"
							rel="noopener"
							className="b-subfooter-labels__link"
							title="Hight Tech Park"
							style={{display: "none"}}
						>
							<HightTechPark className="b-subfooter-labels__icon-htp" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
);

export default SubFooter
