import React from 'react';
import Helmet from 'react-helmet';
import config from '../../data/config';

import isDevOrLive from '../../utils/isDevOrLive';

const SEO = ({
	title,
	description,
	cover,
	lang,
	slug = '',
	postSEO = false,
	slugs = null,
	__typename = '',
	fbappid,
	twitterUser,
	ogDescription,
	twitterDescription,
	ogCover,
	twitterCover,
	location
}) => {
	let alternates
	let langs = slugs

	if (slugs && slugs.length > 0 && typeof slugs === 'string') {
		langs = JSON.parse(slugs)
	}

	const langPrefix = lang === config.defaultLangKey ? '/' : `/${lang}/`
	const url = config.siteUrl + langPrefix + slug
	const meta = {
		title,
		description,
		image: cover,
		url,
	}

	if (__typename === 'ContentfulSeo') {
	meta.description = description.description
	}

	if (!title || title.length <= 0) {
		meta.title = config.siteTitle
	}

	if (!description || description.length <= 0) {
		meta.description = config.siteDescription
	}

	if (!cover) {
		meta.image = config.siteLogo
	}

	if (slugs) {
		alternates = Object.keys(langs).filter(l => l !== lang)
	}

	const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
	const blogURL = config.siteUrl + config.pathPrefix
	const schemaOrgJSONLD = [
		{
			'@context': 'http://schema.org',
			'@type': 'WebSite',
			url: meta.url,
			name: title,
			alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
		},
	]

	if (postSEO) {
		schemaOrgJSONLD.push(
			{
				'@context': 'http://schema.org',
				'@type': 'BreadcrumbList',
				itemListElement: [
					{
					'@type': 'ListItem',
					position: 1,
						item: {
							'@id': meta.url,
							name: meta.title,
							image: meta.image,
						},
					},
				],
			},
			{
				'@context': 'http://schema.org',
				'@type': 'BlogPosting',
				url: meta.url,
				name: meta.title,
				alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
				headline: meta.title,
				image: {
					'@type': 'ImageObject',
					url: meta.image,
				},
				description: meta.description,
			}
		)
	}

	return (
		<Helmet>
			<html lang={lang} />
			<title>
			{meta.title}
			</title>
			{alternates &&
				alternates.map((lang, key) => (
					<link
					rel="alternate"
					key={key}
					hreflang={lang}
					href={`${config.siteUrl}${langs[lang]}`}
					/>
				))}

			{/* General tags */}
			<meta charSet="utf-8" />
			<meta name="description" content={meta.description} />

			<meta name="image" content={meta.image} />
			{!isDevOrLive && <meta name="robots" content="noindex" />}
			{location && location.pathname &&
			<link rel="canonical" href={config.siteUrl + location.pathname} />
			}
			{/* Schema.org tags */}
			<script type="application/ld+json">
			{JSON.stringify(schemaOrgJSONLD)}
			</script>

			{/* OpenGraph tags */}
			<meta property="og:url" content={meta.url} />
			<meta property="og:locale" content={lang} />
			{postSEO ? <meta property="og:type" content="article" /> : <meta property="og:type" content="website" />}
			<meta property="og:title" content={meta.title} />
			<meta property="og:description" content={ogDescription} />
			<meta property="og:image" content={`https:${ogCover}`} />
			<meta
			property="fb:app_id"
			content={fbappid}
			/>
			{/* Twitter Card tags */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta
				name="twitter:creator"
				content={twitterUser}
			/>
			<meta name="twitter:title" content={meta.title} />
			<meta name="twitter:description" content={twitterDescription} />
			<meta name="twitter:image" content={`https:${twitterCover}`} />
		</Helmet>
	);
};

export default SEO;
